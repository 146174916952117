﻿$block: 'f-icon';

.#{$block} {
	margin-right: 4px;

	&_solo {
		margin-right: 0;
	}

	&:before {
		font-family: icons;
		font-size: iherit;
		vertical-align: bottom;
		font-style: normal;
		font-weight: 400;
	}

	&_bell-o {
		&:before {
			content: '\E015';
		}
	}

	&_cog-o {
		&:before {
			content: '\E020';
		}
	}

	&_cogs {
		&:before {
			content: '\E021';
		}
	}

	&_credit-card {
		&:before {
			content: '\E042';
		}
	}

	&_all-files {
		&:before {
			content: '\E059';
		}
	}

	&_smile-o {
		&:before {
			content: '\E094';
		}
	}

	&_all-files-alt {
		&:before {
			content: '\E107';
		}
	}

	&_create-post {
		&:before {
			content: '\E112';
		}
	}

	&_circle-large {
		&:before {
			content: '\E225';
		}
	}

	&_cog {
		&:before {
			content: '\E517';
		}
	}

	&_bell {
		&:before {
			content: '\E502';
		}
	}

	&_rub {
		// font-size: 0;
		&:before {
			text-transform: uppercase;
			font-family: 'ALSRubl-Tahoma';
			content: 'Р';
			// font-weight: 700;
			font-size: 18px;
		}

		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	left: 50%;
		// 	top: 50%;
		// 	transform: translate(-50%,-50%);
		// 	width: 13px;
		// 	height: 13px;
		// 	border: 1px solid $basecolor;
		// 	border-radius: 50%;
		// 	padding: 2px;
		// 	margin-top: -1px;
		// }
	}
}