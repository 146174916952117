﻿$block: '.table';

#{$block} {
	margin: 0 0 1rem;
	max-width: inherit;
	// table-layout: inherit;

	&__row {
		border-bottom: 1px solid #e8e8e8;

		&:last-child {
			border-bottom: none;
		}
	}

	&__header {
		padding: 8px 16px 8px 0;

		#{$block}__row:first-child & {
			font-weight: 700;
			border-bottom: 2px solid #2ab27b;
			text-align: left;

			#{$block}_error & {
				border-color: #cb5234;
			}
		}

		&:last-child {
			#{$block}_press & {
				width: 100%;
			}
		}

		#{$block}_press & {
			white-space: nowrap;
			@include bp(lap) {
				padding-right: 48px;
			}
		}
	}

	&__data {
	}

	&__data-inner {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 8px 16px 8px 0;
	}

	&_fill {
		width: 100%;
	}

	&_fixed {
		table-layout: fixed;
		width: 100%;
	}
}

// table tr:first-child th:not(:only-of-type){font-weight:700;border-bottom:2px solid #2ab27b;text-align:left}