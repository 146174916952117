html {
	height: 100%;
	width: 100%;
	// overflow-x: hidden;
}

body {
	color: $basecolor;
	background-color: #f9f9f9;
	// cursor: default;
	font-family: 'Lato';
	font-weight: 400;
	font-size: $baseSize;
	line-height: $baseLine;
	overflow-x: hidden;
	// lost-utility: edit;
	height: 100%;
	// text-rendering: optimizelegibility;
}

a, .lnk {
	color: $lnk;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

p {
	margin-bottom: $lowSize;
}