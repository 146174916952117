﻿$bps: "(min-width: 360px)"	"mobile",
			"(min-width: 480px)"	"palm",
			"(min-width: 610px)"	"lap",
			"(min-width: 800px)"	"portable",
			"(min-width: 960px)"	"p960",
			"(min-width: 1024px)"	"desk",
			"(min-width: 1280px)"	"lg",
			"(min-width: 1360px)"	"nbook",
			"(min-width: 1600px)"	"xl",
			"(min-width: 1920px)"	"fh",
			"(min-width: 2500px)"	"xxl";

$bpsOnly: "(min-width: 360px) and (max-width: 479px)"		"mobile",
					"(min-width: 480px) and (max-width: 609px)"		"palm",
					"(min-width: 610px) and (max-width: 799px)"		"lap",
					"(min-width: 800px) and (max-width: 1023px)"	"portable",
					"(min-width: 1024px) and (max-width: 1279px)"	"desk",
					"(min-width: 1280px) and (max-width: 1399px)"	"lg";

@mixin bp($point, $only: "false") {
	@if $only == "false" {
		@each $bp in $bps {
			$query: nth($bp, 1);
			$name: nth($bp, 2);
			@if $name == $point {
				@media #{$query} {
					@content;
				}
			}
		}
	}
	@else {
		@each $bp in $bpsOnly {
			$query: nth($bp, 1);
			$name: nth($bp, 2);
			@if $name == $point {
				@media #{$query} {
					@content;
				}
			}
		}
	}
}
