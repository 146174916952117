﻿.apps-info {
	&__title {
	}

	&__title {
		font-size: 34px;
		font-weight: 700;
		line-height: 2.43rem;
		margin-bottom: 16px;
	}

	&__things-list {

	}

	&__things-list-item {
		margin-bottom: $baseLine;
	}
}