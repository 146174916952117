.widgetNerta {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 0 rgba(0,0,0,.25);
	padding: 21px 25px 17px;
	position: relative;
	border: 1px solid #e8e8e8;
	// margin-bottom: $baseLine;

	&__header {
		flex-wrap: nowrap;
		// overflow: hidden;
		// align-items: center;
		margin-bottom: 12px;
		align-items: center;
	}

	&__title {
		// margin-bottom: 8px;
		font-size: 20px;
		font-weight: 700;
		white-space: nowrap;
		max-width: 100%;
		overflow: hidden;
		// text-overflow: ellipsis;
		// border-right: 5px solid transparent;
		position: relative;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 15px;
			background-image: linear-gradient(to right, rgba(#fff, 0) 0, #fff 50%);
			// background: red;
			z-index: 999;
		}
	}

	&__title-inner {
		color: $basecolor;

		&:hover {
			color: $lnk2;
			text-decoration: none;
		}
	}

	.l-grid__cell {
		// overflow: hidden;

		&:nth-child(2) {
			flex: none;
		}
	}

	&__footer {
		flex-wrap: nowrap;
		white-space: nowrap;
		align-items: baseline;
		margin-top: -2px;

		> .l-grid__cell {
			&:nth-child(2) {
				flex: none;
			}
		}
	}

	&__profit {
		@extend .tableNerta__cell--money;
	}

	&__options-btn {
		margin-left: .25rem;
		margin-right: -5px;
		padding-left: 0;
		padding-right: 0;
		color: $subtext;
	}

	&__attention-btn {
		// margin-left: .25rem;
		padding-left: 0;
		padding-right: 0;
		color: #cb5234;
	}

	&__options-icon,
	&__attention-icon {
		&:before {
			font-size: 20px;
			text-align: right;
		}
	}

	&__options-popup,
	&__attention-popup {
		top: 46px;
		right: 24px;
	}
}