﻿.tabs {
	// display: flex;

	&__lnk {
		display: inline-block;
		padding: 12px 32px 11px;
		margin-bottom: -1px;
		font-size: $baseSize;
		font-weight: 700;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;

		&--selected {
			background: #fff;
			border-color: $line $line white;
			position: relative;
			z-index: 1;
			border-radius: 4px 4px 0 0;
			color: $basecolor;
			text-decoration: none;

			&:hover {
				text-decoration: none;
				cursor: default;
			}
		}

		&--second {
			// margin-left: auto;
			float: right;
			color: $subtext;
		}
	}
}