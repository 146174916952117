.tab-control {
	width: 100%;

	&__tabs {
		width: 100%;
	}

	&__item {
		display: inline-block;
		padding: 12px 32px 11px;
		margin-bottom: -1px;
		font-size: $baseSize;
		font-weight: 700;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;

		&--selected {
			background: #fff;
			border-color: $line $line white;
			position: relative;
			z-index: 1;
			border-radius: 4px 4px 0 0;
			color: $basecolor;
			text-decoration: none;

			&:hover {
				text-decoration: none;
				cursor: default;
			}
		}

		&--second {
			// margin-left: auto;
			float: right;
			color: $subtext;
		}
	}

	&__pane {
		width: 100%;
		background: #fff;
		border: 1px solid #e8e8e8;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 1px 0 rgba(0,0,0,.25);

		padding: 16px 12.8px;
		@include bp(portable) {
			padding: 32px;
		}
	}
}