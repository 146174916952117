﻿.modal {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(black,.4);
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 999;

	&--show {
		display: block;
	}

	&__dialog {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1000;
		background: transparent;
		width: 100%;
		max-width: 100%;

		@include bp(portable) {
			top: 20vh;
		}

		@include bp(lg) {
			// width: auto;
			max-width: 1100px;
		}

		overflow-y: auto;
	}

	&__cover {
		background-color: $modalHeader;
		background-image: linear-gradient(transparent 50%, white 50%);
		box-shadow: 0 1px 10px rgba(black,.6);
		overflow: hidden;
		position: relative;
		min-height: 286px; // tmp fix

		@include bp(portable) {
			margin: 10px;
			margin-bottom: 30px;
			border-radius: 8px;
		}

	}

	&__header {
		// position: fixed;
		height: 50px;
		// padding: 12px 22px 0;
		padding: 0 22px 0;
		justify-content: space-between;
		@extend %grid--auto;
		align-items: center;

		@include bp(portable) {
			position: relative;
		}

	}

	&__close {
		text-align: right;
		line-height: 0;
	}

	&__section {
		padding: 22px 22px 0;
		background: white;
		margin-bottom: 22px;
	}

	// &__content {
	// 	display: none;
	// 	width: 100%;
	// 	min-height: 100px;
	// 	position: relative;
	// }

	&__title {
		font-size: 20px;
		font-weight: 900;
	}
}