.navbar {
	width: $navbarWidth;
	position: fixed;
	// display: flex;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	background: $navbarBg;
	color: #fff;
	overflow-y: auto;
	transform: translate3d(-$navbarWidth, 0px, 0px);
	transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;

	.page_indent & {
		transform: translate3d(0px, 0px, 0px);
	}
}
