﻿$block: '.list';

#{$block} {
	margin: 0 0 16px 32px;

	&__item {
		position: relative;

		&:before {
			display: inline-block;
			position: absolute;
			left: -17px;
			content: '•';

			#{$block}_order & {
				counter-increment: list;
				content: counter(list) '.';
			}
		}
	}

	&_order {
		counter-reset: list;
	}
}