﻿.loader {
	position: absolute;
	will-change: transform;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);

	margin-top: $baseLine / 2;
}

.loader {
	border: 1px solid #a4a4a4;
	width: 100%;
	// max-width: 320px;
	max-width: 240px;
	height: 12px;
	background-image: linear-gradient(135deg, #6187f2 0%, #6187f2 25%, #5679da 25%, #5679da 50%, #6187f2 50%, #6187f2 75%, #5679da 75%, #5679da 100%);
	background-repeat: repeat;
	background-position: 0px 0px;
	background-size: 16px 16px;
	background-clip: content-box;
	padding: 1px;
	animation: loading 1s linear infinite;

	&:before {
		content: attr(data-text);
		display: block;
		position: absolute;
		top: -$baseLine - 5px;
		width: 100%;
		text-align: center;
		cursor: default;
	}
}

@keyframes loading {
	from {
		background-position: 0px 0px;
	}
	to {
		background-position: -16px 0px;
	}
}

// @keyframes progress {
// 	0% {
// 		min-width: 100%;
// 	}
// 	30% {
// 		min-width: 80%;
// 	}
// 	50% {
// 		min-width: 65%;
// 	}
// 	69% {
// 		min-width: 20%;
// 	}
// 	85% {
// 		min-width: 0;
// 	}
// 	100% {
// 		min-width: 0;
// 	}
// }