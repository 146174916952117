﻿.apps-catalog {
	@include width(content);

	&__header {
		width: inherit;
	}

	&__content {
		width: inherit;
	}
}