﻿.header {
	display: flex;
	background: #fff;
	height: $headerHeight;
	// margin-bottom: 48px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	position: fixed;
	z-index: 999;
	// width:100%;
	top: 0;
	left: 0;
	right: 0;
	transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;

	.page_indent & {
		transform: translate3d($navbarWidth, 0, 0);

		&:after {
			position: absolute;
			top: $headerHeight;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100vh;
			background: rgba(black,.1);
			content: '';
			z-index: 1;
		}
	}
}
