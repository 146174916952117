﻿.apps-app {
	@include width(content);

	&__aside {
		lost-column: 1/4 no-flex 35px;
		display: none;
	}

	&__content {
		// lost-column: 3/4 no-flex 35px;
		lost-column: 4/4 no-flex 35px; // tmp
	}

	&__icon {
		border-radius: 16px;
	}
}