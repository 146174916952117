﻿$block: 'media-list';

.#{$block} {
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 6px;

	&_nude {
		border: 0;
		border-radius: inherit;
	}

	&__item {
		position: relative;

		&:first-child {
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
		}

		&:last-child {
			border-bottom-left-radius: inherit;
			border-bottom-right-radius: inherit;
		}

		&:not(:first-child) {
			&:before {
				position: absolute;
				border-top: 1px solid #e8e8e8;
				content: '';
				top: 0;
				left: 1rem;
				right: 1rem;

				.#{$block}_interactive .#{$block}__item:hover + & {
					border-top: 1px solid transparent;
				}
			}
		}
	}

	&__item-inner {
		padding: 1rem;
		display: flex;
		align-items: center;
		position: relative;

		border-radius: inherit;

		border-bottom: 1px solid transparent;
		border-top: 1px solid transparent;
		
		color: #555459;

		&:before {
			.#{$block}_interactive & {
				color: $subtext;
				position: absolute;
				top: 50%;
				right: calc(1rem - 6px);
				transform: translateY(-50%);
				content: '\E254';
				display: inline-block;
				font-family: icons;
				font-size: 20px;
			}
		}

		&:hover {
			.#{$block}_interactive & {
				background: #edf7fd;
				text-decoration: none;
			}

			.#{$block}_interactive .#{$block}__item:not(:first-child) & {
				border-top-color: #d3ecfa;
			}

			.#{$block}_interactive .#{$block}__item:not(:last-child) & {
				border-bottom-color: #d3ecfa;
			}
		}
	}

	&__media {
		width: 40px;
		height: 40px;
		min-width: 40px;
		margin-right: 1rem;
		border-radius: 6px;
	}

	&__text {
		font-size: $lowSize;
		line-height: 20px;
		white-space: nowrap;
		margin-right: 1rem;
		flex-grow: 1;
	}

	%#{$block}__text {
		padding-right: .5rem;
		text-overflow: ellipsis;
		overflow: hidden;
		color: $basecolor;
	}

	&__title {
		@extend %#{$block}__text;
		font-weight: 700;
	}

	&__description {
		@extend %#{$block}__text;
	}

	&__action {
	}
}