﻿.userInfo {
	display: flex;
	padding: 16px 24px;

	&:hover {
		background: $navHover;
		cursor: pointer;
	}

	&__name {
		order: 1;
		margin-left: 16px;
		font-weight: 700;
		white-space: nowrap;
		position: relative;
		top: 1px;
		text-overflow: ellipsis;
		overflow: hidden;

		&:before {
			content: attr(data-text);
			display: block;
			color: $navDark;
			text-transform: uppercase;
			font-size: 12.8px;
			font-weight: 400;
			margin-bottom: -4px;
		}
	}

	&__avatar {
		border-radius: 4px;
		flex: 0 0 48px;
		width: 48px;
		height: 48px;
		display: block;
	}
}