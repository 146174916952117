﻿/**
 * @preserve jQuery PeriodPicker plugin v3.4.1
 * @homepage http://xdsoft.net/jqplugins/periodpicker/
 * @copyright (c) 2016 xdsoft.net Chupurnov Valeriy
 * @license PRO http://xdsoft.net/jqplugins/periodpicker/license/
 */
.xdsoft_noselect,
.xdsoft_noselect * {
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
}
.period_picker_input {
	border: 0;
	line-height: 28px;
	font-size: 13px;
	-webkit-border-radius: 3px;
		 -moz-border-radius: 3px;
					border-radius: 3px;
	color: #000;
	cursor: pointer;
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
	text-align: center;
	white-space: nowrap;
	outline: 0;
}
.period_picker_input:before {
	position: absolute;
	top: 1px;
	right: 1px;
	bottom: 1px;
	left: 1px;
	padding: 0;
	content: '';
	-webkit-border-radius: 2px;
		 -moz-border-radius: 2px;
					border-radius: 2px;
	background: #fff;
	-webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
		 -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
					box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.period_picker_input:active:before {
	background-color: #f6f5f3;
	-webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
		 -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
					box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
}
.period_picker_input .period_button_text {
	padding: 0 13px;
	position: relative;
}
.period_picker_input .icon_clear,
.period_picker_input .icon_calendar {
	width: 16px;
	height: 16px;
	background: 50% no-repeat;
	-moz-background-size: 100% 100%;
		-o-background-size: 100% 100%;
			 background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23010202%22%20d%3D%22M0%206h3v3H0V6zm4%203h3V6H4v3zm4%200h3V6H8v3zm4%200h3V6h-3v3zm0-7v3h3V2h-3zM8%205h3V2H8v3zM4%205h3V2H4v3zm-4%208h3v-3H0v3zm4%200h3v-3H4v3zm4%200h3v-3H8v3z%22%2F%3E%3C%2Fsvg%3E");
	margin-right: 9px;
	margin-bottom: 2px;
	position: relative;
	vertical-align: middle;
	display: inline-block;
}
.period_picker_input .icon_clear:after,
.period_picker_input .icon_calendar:after {
	visibility: hidden;
	content: '\00A0';
}
.period_picker_input .icon_clear {
	background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%3Cpath%20fill%3D%22%23444444%22%20d%3D%22M8%200c-4.418%200-8%203.582-8%208s3.582%208%208%208%208-3.582%208-8-3.582-8-8-8zM8%2014.5c-3.59%200-6.5-2.91-6.5-6.5s2.91-6.5%206.5-6.5%206.5%202.91%206.5%206.5-2.91%206.5-6.5%206.5z%22%3E%3C%2Fpath%3E%0A%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M10.5%204l-2.5%202.5-2.5-2.5-1.5%201.5%202.5%202.5-2.5%202.5%201.5%201.5%202.5-2.5%202.5%202.5%201.5-1.5-2.5-2.5%202.5-2.5z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
	margin-right: 0px;
	margin-left: 9px;
	-webkit-transition: opacity 0.2s linear, background 0.2s linear;
	-moz-transition: opacity 0.2s linear, background 0.2s linear;
	-o-transition: opacity 0.2s linear, background 0.2s linear;
	transition: opacity 0.2s linear, background 0.2s linear;
	position: relative;
	-webkit-border-radius: 8px;
		 -moz-border-radius: 8px;
					border-radius: 8px;
}
.period_picker_input .icon_clear:hover {
	opacity: 0.8;
}
.period_picker_input .icon_clear:active {
	background-color: #ccc;
}
.period_picker_input .period_button_dash {
	margin-left: 3px;
	margin-right: 2px;
}
.period_picker_box {
	position: absolute;
	left: 0;
	top: 0;
	padding: 0 20px 20px;
	background: #fff;
	font-size: 13px;
	cursor: default;
	overflow: hidden;
	z-index: 33001;
	border: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
		 -moz-box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
					box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
	min-width: 236px;
	min-height: 365px;
	padding-bottom: 80px;
	visibility: hidden;
}
.period_picker_box,
.period_picker_box * {
	-webkit-box-sizing: border-box !important;
		 -moz-box-sizing: border-box !important;
					box-sizing: border-box !important;
	color: #606060;
}
.period_picker_box.with_first_timepicker {
	min-width: -webkit-calc(236px + 87px);
	min-width: -moz-calc(236px + 87px);
	min-width: calc(236px + 87px);
}
.period_picker_box.with_first_timepicker.with_second_timepicker {
	min-width: -webkit-calc(236px + 87px + 87px);
	min-width: -moz-calc(236px + 87px + 87px);
	min-width: calc(236px + 87px + 87px);
}
.period_picker_box.period_picker_maximize {
	border: 0;
	position: fixed;
	-webkit-border-radius: 0;
		 -moz-border-radius: 0;
					border-radius: 0;
	-webkit-box-shadow: none;
		 -moz-box-shadow: none;
					box-shadow: none;
	left: 0px;
	top: 0px;
	min-width: 667px;
	min-height: 395px;
	margin-top: 0px;
	width: 100%	!important;
	height: 100%	!important;
	left: 0 !important;
	top: 0	!important;
}
.period_picker_box.visible {
	visibility: visible;
}
.period_picker_box.animation {
	opacity: 0;
	margin-top: -200px;
	-webkit-transition: .15s linear;
	-moz-transition: .15s linear;
	-o-transition: .15s linear;
	transition: .15s linear;
	-webkit-transition-property: opacity, margin-top;
	-moz-transition-property: opacity, margin-top;
	-o-transition-property: opacity, margin-top;
	transition-property: opacity, margin-top;
}
.period_picker_box.active.animation {
	opacity: 1.0;
	margin-top: 0px;
}
.period_picker_box.xdsoft_inline,
.period_picker_box.animation.xdsoft_inline {
	position: relative;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
	visibility: visible;
	-webkit-box-shadow: none;
		 -moz-box-shadow: none;
					box-shadow: none;
	opacity: 1;
	margin: 0;
	z-index: 0;
}
.period_picker_box .period_picker_error {
	-webkit-box-shadow: 1px 1px 10px red!important;
		 -moz-box-shadow: 1px 1px 10px red!important;
					box-shadow: 1px 1px 10px red!important;
}
.period_picker_box .period_picker_head {
	color: #333;
	position: relative;
	height: 40px;
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
}
.period_picker_box .period_picker_head_title {
	display: inline-block;
	margin-top: 12px;
	color: rgba(0, 0, 0, 0.5);
}
.period_picker_box .period_picker_resizer,
.period_picker_box .period_picker_max_min,
.period_picker_box .period_picker_close {
	background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2256%22%20height%3D%2214%22%20viewBox%3D%220%200%2056%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20fill%3D%22%23000%22%3E%3Cpath%20d%3D%22M16%202h11v10H16V2zm1%201h9v8h-9V3z%22%2F%3E%3Cpath%20d%3D%22M38%209h2V3h-7v2h-2v6h7V9h1V4h-6v1h5v4zm-6-3h5v4h-5V6z%22%2F%3E%3Cpath%20d%3D%22M12%202.714L11.287%202%207%206.286%202.714%202%202%202.714%206.286%207%202%2011.285l.714.715L7%207.714%2011.287%2012l.713-.715L7.714%207%2012%202.714%22%2F%3E%3Cpath%20d%3D%22M44.964%2010.328l.708.708%206.364-6.364-.708-.708-6.364%206.364zm3.415.586l.706.707%203.535-3.534-.706-.707-3.535%203.534z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat;
	opacity: .5;
	right: 0;
}
.period_picker_box .period_picker_resizer {
	position: absolute;
	bottom: 0;
	right: 0;
	font-size: 0;
	line-height: 0;
	width: 14px;
	height: 14px;
	cursor: se-resize;
	z-index: 7;
	display: block;
	background-position: -42px 0;
}
.period_picker_box .period_picker_close,
.period_picker_box .period_picker_max_min {
	position: absolute;
	width: 14px;
	height: 14px;
	top: 10px;
	cursor: pointer;
	background-position: 0 0;
}
.period_picker_box .period_picker_max_min {
	background-position: -14px 0;
	right: 20px;
}
.period_picker_box .period_picker_maximize div span.period_picker_max_min {
	background-position: -28px 0;
}
.period_picker_box .period_picker_years {
	position: relative;
	left: 0;
	height: 30px;
	margin-bottom: 15px;
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
}
.period_picker_box .period_picker_years_selector {
	position: absolute;
	width: 100%;
	height: 30px;
	overflow: hidden;
	font-size: 14px;
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
	-webkit-border-radius: 3px;
		 -moz-border-radius: 3px;
					border-radius: 3px;
	background: #f4f4f3;
}
.period_picker_box .period_picker_years_selector .period_picker_year {
	position: absolute;
	top: 5px;
	width: 3em;
	margin-left: -1.5em;
	font-size: 12px;
	text-align: center;
	cursor: pointer;
	z-index: 11;
	color: rgba(0, 0, 0, 0.5);
	font-weight: 700;
}
.period_picker_box .period_picker_years_selector .period_picker_year:hover {
	color: #000;
}
.period_picker_box .period_picker_years_selector .period_picker_year:after {
	content: '';
	display: block;
	width: 1px;
	height: 9px;
	margin: 2px auto;
	background: rgba(0, 0, 0, 0.2);
}
.period_picker_box .period_picker_years_selector_container {
	position: absolute;
	left: 0;
	top: 0;
	height: 30px;
}
.period_picker_box .period_picker_years_dragger {
	position: absolute;
	top: 0;
	right: 20px;
	width: 70px;
	height: 30px;
	margin-left: -1px;
	font-size: 0;
	line-height: 0;
	z-index: 12;
	cursor: pointer;
}
.period_picker_box .period_picker_years_dragger:after {
	content: '';
	display: block;
	border: 2px solid rgba(0, 0, 0, 0.7);
	background: rgba(255, 255, 255, 0.4);
	-webkit-border-radius: 4px;
		 -moz-border-radius: 4px;
					border-radius: 4px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 1px;
	right: -1px;
}
.period_picker_box .period_picker_years_dragger:hover:after {
	border-color: #000;
	background: rgba(255, 255, 255, 0.6);
}
.period_picker_box .period_picker_years_period {
	background: #ffcf00;
	position: absolute;
	right: 100px;
	width: 1px;
	height: 32px;
	font-size: 0;
	line-height: 0;
	z-index: 9;
	display: none;
}
.period_picker_box .period_picker_first_letters_td,
.period_picker_box .period_picker_first_letters_tr {
	padding: 0 6px;
}
.period_picker_box table {
	border-spacing: 0;
	border-collapse: separate;
}
.period_picker_box table th,
.period_picker_box table td {
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
	padding: 0;
	text-align: left;
	vertical-align: top;
	border-collapse: collapse;
}
.period_picker_box table th.period_picker_first_letters_td,
.period_picker_box table td.period_picker_first_letters_td,
.period_picker_box table th.period_picker_first_letters_tr,
.period_picker_box table td.period_picker_first_letters_tr {
	padding: 0;
}
.period_picker_box table td.period_picker_selector_week {
	width: 16px;
	padding: 2px 5px 0;
	text-align: center;
}
.period_picker_box .period_picker_days table td {
	vertical-align: top;
}
.period_picker_box .period_picker_days table table {
	width: 184px;
	table-layout: fixed;
	border-spacing: 0;
}
.period_picker_box .period_picker_days table table.period_picker_first_letters_table th {
	text-align: right;
	font-size: 9px;
	height: 10px;
	line-height: 10px;
	padding: 0;
	color: rgba(0, 0, 0, 0.5);
}
.period_picker_box .period_picker_days table table th {
	width: 23px;
	padding: 0 5px;
	height: 25px;
	white-space: nowrap;
}
.period_picker_box .period_picker_days table table td {
	width: 23px;
	padding: 0 5px;
	height: 25px;
	cursor: pointer;
	empty-cells: hide;
	vertical-align: middle;
}
.period_picker_box .period_picker_days table table td:hover {
	background: #EBEBEB;
	-webkit-border-radius: 5px;
		 -moz-border-radius: 5px;
					border-radius: 5px;
}
.period_picker_box .period_picker_days table table th.period_picker_holiday,
.period_picker_box .period_picker_days table table td.period_picker_holiday {
	color: #C11924;
}
.period_picker_box .period_picker_days table table th.period_picker_month {
	border: 0;
	font-weight: 700;
	padding: 14px 0 10px 12px;
	text-transform: uppercase;
}
.period_picker_box .period_picker_days table table th.period_picker_month:hover {
	color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
}
.period_picker_box .period_picker_days table table td.period_picker_empty,
.period_picker_box .period_picker_days table table td.period_picker_empty:hover {
	cursor: default!important;
	background: none!important;
	-webkit-border-radius: 0;
		 -moz-border-radius: 0;
					border-radius: 0;
}
.period_picker_box .period_picker_days table table td.period_picker_gray_period,
.period_picker_box .period_picker_days table table td.period_picker_gray_period:hover {
	color: #aaa;
}
.period_picker_box .period_picker_days table td.period_picker_month12:not(:last-child) {
	position: relative;
}
.period_picker_box .period_picker_days table td.period_picker_month12:not(:last-child):after {
	content: '';
	display: block;
	border-right: 2px solid rgba(0, 0, 0, 0.1);
	position: absolute;
	top: -16px;
	bottom: 0;
	right: -5px;
	display: none;
}
.period_picker_box .period_picker_days table td.period_picker_selected {
	background: #FFEBA0;
	-webkit-border-radius: 0;
		 -moz-border-radius: 0;
					border-radius: 0;
}
.period_picker_box .period_picker_days table td.period_picker_selected :hover {
	background-color: #FFF5D0;
	-webkit-border-radius: 0;
		 -moz-border-radius: 0;
					border-radius: 0;
}
.period_picker_box .period_picker_days table td.period_picker_selected_start,
.period_picker_box .period_picker_days table td.period_picker_selected_start:hover {
	-webkit-border-radius: 5px 0 0 0;
		 -moz-border-radius: 5px 0 0 0;
					border-radius: 5px 0 0 0;
}
.period_picker_box .period_picker_days table td.period_picker_selected_end,
.period_picker_box .period_picker_days table td.period_picker_selected_end:hover {
	-webkit-border-radius: 0 0 5px 0;
		 -moz-border-radius: 0 0 5px 0;
					border-radius: 0 0 5px 0;
}
.period_picker_box span.period_picker_selector_week_cap {
	visibility: hidden;
}
.period_picker_box span.period_picker_selector_week,
.period_picker_box span.period_picker_selector_week_cap {
	padding: 0;
	background-color: #ccc;
	display: inline-block;
	width: 2px;
	height: 16px;
}
.period_picker_box .period_picker_gray_period,
.period_picker_box .period_picker_weekday,
.period_picker_box .period_picker_holiday,
.period_picker_box .period_picker_empty {
	text-align: right;
}
.period_picker_box .period_picker_month b {
	display: none;
}
.period_picker_box .period_picker_submit_dates {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	font-size: 13px;
	text-align: center;
	margin: 0 20px 15px;
	background-color: #fff;
}
.period_picker_box .period_picker_submit_dates:before {
	content: '';
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin: 0px;
	margin-bottom: 20px;
	display: block;
}
.period_picker_box .period_picker_submit_dates .input_box {
	padding-right: 7px;
	background: #fff;
	-webkit-box-shadow: inset 0 0 0 1px #cbcbcb;
		 -moz-box-shadow: inset 0 0 0 1px #cbcbcb;
					box-shadow: inset 0 0 0 1px #cbcbcb;
	position: relative;
	display: block;
	-webkit-box-sizing: border-box;
		 -moz-box-sizing: border-box;
					box-sizing: border-box;
	cursor: text;
}
.period_picker_box .period_picker_submit_dates .input_focused_yes .input_box {
	-webkit-box-shadow: inset 0 0 0 1px #d0ba65,0 0 10px #fc0;
		 -moz-box-shadow: inset 0 0 0 1px #d0ba65,0 0 10px #fc0;
					box-shadow: inset 0 0 0 1px #d0ba65,0 0 10px #fc0;
}
.period_picker_box .period_picker_submit_dates input {
	width: 6.1em;
}
.period_picker_box .period_picker_submit_dates input.input_control {
	position: relative;
	z-index: 3;
	width: 100%;
	margin: 0;
	font-size: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-appearance: none;
	-webkit-box-sizing: border-box;
		 -moz-box-sizing: border-box;
					box-sizing: border-box;
	padding: .4em 0;
	border: 0;
	outline: 0;
	background: 0 0;
	color: #000;
	padding-left: 7px;
}
.period_picker_box.without_bottom_panel {
	min-height: 311px;
	padding-bottom: 0px;
}
.period_picker_box.without_bottom_panel .period_picker_submit_dates {
	display: none;
}
.period_picker_box.without_yearsline {
	min-height: 335px;
}
.period_picker_box.without_header {
	min-height: 325px;
}
.period_picker_box.without_header .period_picker_head {
	height: 10px;
}
.period_picker_box.without_header.without_yearsline {
	min-height: 325px;
}
.period_picker_box.without_header.without_bottom_panel {
	min-height: 271px;
}
.period_picker_box.without_yearsline.without_bottom_panel {
	min-height: 281px;
}
.period_picker_box.without_yearsline.without_header.without_bottom_panel {
	min-height: 241px;
}
.period_picker_box .period_picker_from_time_block,
.period_picker_box .period_picker_to_time_block,
.period_picker_box .period_picker_from_block,
.period_picker_box .period_picker_to_block {
	margin-right: 5px;
	width: 81px;
	font-size: 13px;
	line-height: 28px;
	color: #000;
	position: relative;
	display: inline-block;
	margin-bottom: 5px;
}
.period_picker_box .period_picker_from_time_block,
.period_picker_box .period_picker_to_time_block {
	width: 60px;
}
.period_picker_box.xdsoft_norange .period_picker_from_block ~ span {
	display: none;
}
.period_picker_box .period_picker_to_time_block,
.period_picker_box .period_picker_to_block {
	margin-right: 0px;
	margin-left: 5px;
}
.period_picker_box .period_picker_show {
	display: inline-block;
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
	text-align: center;
	white-space: nowrap;
	outline: 0;
	position: absolute;
	right: 0;
	-webkit-border-radius: 3px;
		 -moz-border-radius: 3px;
					border-radius: 3px;
	color: #000;
	line-height: 28px;
	font-size: 13px;
	cursor: pointer;
	border: 0;
	background: 0 0;
	z-index: 3;
}
.period_picker_box .period_picker_show + .period_picker_show {
	right: 50px;
}
.period_picker_box .period_picker_show:before {
	position: absolute;
	top: 1px;
	right: 1px;
	bottom: 1px;
	left: 1px;
	padding: 0;
	content: '';
	-webkit-border-radius: 2px;
		 -moz-border-radius: 2px;
					border-radius: 2px;
	background: #ffdb4d;
	-webkit-box-shadow: 0 0 0 1px rgba(191, 153, 0, 0.8);
		 -moz-box-shadow: 0 0 0 1px rgba(191, 153, 0, 0.8);
					box-shadow: 0 0 0 1px rgba(191, 153, 0, 0.8);
}
.period_picker_box .period_picker_show span {
	position: relative;
	padding: 0 13px;
}
.period_picker_box .period_picker_show:focus {
	-webkit-box-shadow: 0 0 6px #fc0;
		 -moz-box-shadow: 0 0 6px #fc0;
					box-shadow: 0 0 6px #fc0;
}
.period_picker_box .period_picker_show:active:before {
	background-color: #fc0;
	-webkit-box-shadow: 0 0 0 1px rgba(159, 127, 0, 0.8);
		 -moz-box-shadow: 0 0 0 1px rgba(159, 127, 0, 0.8);
					box-shadow: 0 0 0 1px rgba(159, 127, 0, 0.8);
}
.period_picker_box .period_picker_work {
	vertical-align: top;
	height: 100%;
	position: relative;
}
.period_picker_box .period_picker_timepicker_box {
	width: 87px;
	display: block;
	top: 0px;
	bottom: 100px;
	left: 0px;
	position: absolute;
}
.period_picker_box .period_picker_timepicker_box + .period_picker_days + .period_picker_timepicker_box {
	left: auto;
	right: 0px;
}
.period_picker_box.with_first_timepicker .period_picker_days {
	width: -webkit-calc(100% - 87px);
	width: -moz-calc(100% - 87px);
	width: calc(100% - 87px);
	margin-left: 87px;
}
.period_picker_box.with_first_timepicker.with_second_timepicker .period_picker_days {
	width: -webkit-calc(100% - 174px);
	width: -moz-calc(100% - 174px);
	width: calc(100% - 174px);
	margin-left: 87px;
}
.period_picker_box .xdsoft_navigate {
	background: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20%3F%3E%0D%0A%3Csvg%20height%3D%2212px%22%20version%3D%221.1%22%20viewBox%3D%220%200%209%2012%22%20width%3D%229px%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0D%0A%09%3Ctitle%2F%3E%0D%0A%09%3Cdesc%2F%3E%0D%0A%09%3Cdefs%2F%3E%0D%0A%09%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%3E%0D%0A%09%09%3Cg%20fill%3D%22%23444444%22%20id%3D%22Core%22%20transform%3D%22translate(-218.000000%2C%20-90.000000)%22%3E%0D%0A%09%09%09%3Cg%20id%3D%22chevron-left%22%20transform%3D%22translate(218.500000%2C%2090.000000)%22%3E%0D%0A%09%09%09%09%3Cpath%20d%3D%22M7.4%2C1.4%20L6%2C0%20L-8.8817842e-16%2C6%20L6%2C12%20L7.4%2C10.6%20L2.8%2C6%20L7.4%2C1.4%20Z%22%20id%3D%22Shape%22%2F%3E%0D%0A%09%09%09%3C%2Fg%3E%0D%0A%09%09%3C%2Fg%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E") no-repeat center center;
	position: absolute;
	top: 0px;
	left: -13px;
	bottom: 100px;
	width: 15px;
	opacity: 0.7;
	-webkit-transition: opacity .3s linear;
	-moz-transition: opacity .3s linear;
	-o-transition: opacity .3s linear;
	transition: opacity .3s linear;
}
.period_picker_box .xdsoft_navigate:hover {
	opacity: 1.0;
}
.period_picker_box .xdsoft_navigate:active {
	margin-top: 2px;
}
.period_picker_box .xdsoft_navigate.xdsoft_navigate_next {
	background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20%3F%3E%0D%0A%3Csvg%20height%3D%2212px%22%20version%3D%221.1%22%20viewBox%3D%220%200%209%2012%22%20width%3D%229px%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0D%0A%09%3Ctitle%2F%3E%0D%0A%09%3Cdesc%2F%3E%0D%0A%09%3Cdefs%2F%3E%0D%0A%09%3Cg%20transform%3D%22rotate(180%204.1999969482421875%2C6)%20%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%3E%0D%0A%09%09%3Cg%20fill%3D%22%23444444%22%20id%3D%22Core%22%20transform%3D%22translate(-218.000000%2C%20-90.000000)%22%3E%0D%0A%09%09%09%3Cg%20id%3D%22chevron-left%22%20transform%3D%22translate(218.500000%2C%2090.000000)%22%3E%0D%0A%09%09%09%09%3Cpath%20d%3D%22M7.4%2C1.4%20L6%2C0%20L-8.8817842e-16%2C6%20L6%2C12%20L7.4%2C10.6%20L2.8%2C6%20L7.4%2C1.4%20Z%22%20id%3D%22Shape%22%2F%3E%0D%0A%09%09%09%3C%2Fg%3E%0D%0A%09%09%3C%2Fg%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E");
	left: auto;
	right: -15px;
}
.period_picker_box.without_bottom_panel .xdsoft_navigate {
	bottom: 0px;
}


// ==================================================
// Timepicker
// ==================================================

.xdsoft_noselect {
	user-select: none;
}
.periodpicker_timepicker {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	font-size: 1em;
	overflow: hidden;
	font-size: 16px;
	padding: 0;
}
.periodpicker_timepicker,
.periodpicker_timepicker * {
}
.periodpicker_timepicker input.periodpicker_key_hooker {
	border: 0 !important;
	background: transparent !important;
	outline: 0 !important;
	width: 0 !important;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	font-size: 0 !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div {
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 50%;
	text-align: center;
	cursor: default;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 {
	width: 25%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4 {
	left: 25%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4 + div.periodpicker_col4 {
	left: 50%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4 + div.periodpicker_col4 + div.periodpicker_col4 {
	left: 75%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 {
	width: 33.3333333333%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 + div.periodpicker_col3 {
	left: 33.3333333333%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 + div.periodpicker_col3 + div.periodpicker_col3 {
	left: 66.6666666666%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 {
	width: 50%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 + div.periodpicker_col2 {
	left: 50%;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div:not(.draggable) > div {
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div > div > div {
	height: 22px;
	line-height: 23px;
	position: relative;
	color: #727272;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div > div > div.active {
	color: #4B4B4B;
	font-size: 1.1em;
	font-weight: 700;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4:not(.periodpicker_ampmpicker_box):not(.draggable) div.active:before,
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 + div.periodpicker_col2:not(.periodpicker_ampmpicker_box):not(.draggable) div.active:before,
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 + div.periodpicker_col3:not(.periodpicker_ampmpicker_box):not(.draggable) div.active:before {
	content: ":";
	position: absolute;
	font-size: 16px;
	margin-left: -1px;
	left: 0;
	font-weight: 700;
	color: #797575;
	margin-top: -1px;
	z-index: 1;
}
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 + div.periodpicker_col2 div.active:before {
	margin-left: -9px;
}
.periodpicker_timepicker:before {
	content: "";
	position: absolute;
	left: 0px;
	right: 0px;
	height: 20px;
	z-index: 2;
	box-shadow: inset 1px 32px 20px 0 rgba(255,255,255,.65);
}
.periodpicker_timepicker:after {
	content: "";
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0;
	height: 20px;
	box-shadow: inset 0px -32px 20px 0px rgba(255, 255, 255, 0.65);
	z-index: 2;
}
.periodpicker_timepicker .periodpicker_timepicker_center {
	position: absolute;
	top: 50%;
	left: 0px;
	height: 0;
	right: 0px;
}
.periodpicker_timepicker .periodpicker_timepicker_center:before,
.periodpicker_timepicker .periodpicker_timepicker_center:after {
	content: "";
	position: absolute;
	width: 100%;
	border-top: 1px solid #ccc;
}
.periodpicker_timepicker .periodpicker_timepicker_center:before {
	margin-top: -11px;
}
.periodpicker_timepicker .periodpicker_timepicker_center:after {
	margin-top: 13px;
}
.periodpicker_timepicker_dialog {
	position: absolute;
	height: 300px;
	width: 99px;
	background: #fff;
	z-index: 33001;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
	display: none;
}
.periodpicker_timepicker_dialog.visible {
	display: block;
}

// ==================================================
// Cusom styles
// ==================================================

// .period_picker_head {
// 	display: none;
// }

// .period_picker_box {
// 	padding-left: 25px;
// 	padding-top: 25px;
// 	padding-right: 25px;
// }

.period_picker_box {
	display: none;
	@include bp(p960) {
		display: block;
	}
}

.period_picker_selector_week {
	visibility: hidden;
}

.period_picker_box .period_picker_days table table {
	// table-layout: fixed;
	// width: 210px;
}

.period_picker_box .period_picker_days table table td {
	width: auto;
	max-height: 26px;
	padding: 0;
	font-size: 13px;
	text-align: center;
	vertical-align: middle;
	line-height: 26px;
}

.period_picker_box .period_picker_days table table.period_picker_first_letters_table th {
	width: auto;
	padding: 0;
	font-size: 10px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.period_picker_box.with_first_timepicker.with_second_timepicker .period_picker_days {
	margin-left: 79px;
	// margin-left: 20px;
}

.period_picker_box .period_picker_timepicker_box {
	// width: 22px;
	// background: red;
	// top: 0;
	// bottom: 100px;
	// left: 50px;
}

.period_picker_box .period_picker_timepicker_box + .period_picker_days + .period_picker_timepicker_box {
	// right: 50px;
}

.period_picker_box .xdsoft_navigate {
	// left: 20px;
}

.period_picker_box .xdsoft_navigate.xdsoft_navigate_next {
	// right: 20px;
}

.period_picker_box .period_picker_days table table th.period_picker_month {
	text-align: center;
	padding-top: 4px;
	padding-bottom: 4px;
}

// .period_picker_box {
// 	padding-bottom: 20px;
// }

// .period_picker_box.with_first_timepicker.with_second_timepicker {
//	 min-width: 300px;
//	 width: 300px;
// }

// ==================================================
// Button
// ==================================================
.period_picker_input .icon_calendar {
	display: none;
}

.period_button_content_body {
	font-weight: 600;
	color: $basecolor;
}

.period_picker_input .period_button_text {
	line-height: 28px;
	display: inline-block;
}

.period_picker_input {
	border-radius: 4px;
	border: 1px solid #c7cacd;
	vertical-align: baseline;
	background: #fbfbfa;
	font-family: $baseFont;
	height: 30px;
	line-height: 30px;
}

.period_picker_input .period_button_text {
	padding: 0 10px;
}

.period_picker_input:before {
	display: none;
}
.period_picker_input:active:before {
	background-color: inherit;
}

// Navigation arrows

.period_picker_box .xdsoft_navigate {
	// background: red;
	height: 30px;
	bottom: auto;
	top: -45px;
	left: -15px;
}

.period_picker_box .xdsoft_navigate.xdsoft_navigate_next {
	right: -15px;
}

// Clock

.periodpicker_item {
	&:before {
		content: ':';
		position: absolute;
		font-size: 16px;
		font-weight: 700;
		right: -3px;
		top: -1px;
		color: #fff;
	}
	&:after {
		content: '00';
		position: absolute;
		font-weight: 700;
		right: -32px;
		color: #fff;
	}
	&.active {
		&:before {
			color: inherit;
		}
		&:after {
			color: inherit;
		}
	}
}