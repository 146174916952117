.article {
	position: relative;
	// lost-center: 100%;
	// height: 100%;
	flex: 1 0 auto;
	// lost-utility: edit;
	padding-top: 48px;

	&__header {
		// @extend %l-left;
		// @extend %l-right;
		align-items: baseline;
		// justify-content: space-between;
		lost-center: 82% no-flex;
	}

	&--card {
		max-width: 634px;
		margin: 0 auto;
	}

	&--fill {
		@extend %flex !optional;
		@extend %flex--full !optional;
		flex: 1 1 100%;
		align-items: stretch;
		// background: green;
	}
}