﻿$block: 'breadcrumbs';

%#{$block}__arrow {
	font-family: icons;
	content: '\E254';
	color: #babbbf;
	font-weight: 400;
	font-size: 1rem;
	position: absolute;
	top: -1px;
	left: .5rem;
}

%#{$block}__item {
	display: inline-block;
	padding-left: 2rem;
	position: relative;
}

.#{$block} {
	@include width(content);
	// display: flex;
	white-space: nowrap;
	margin-top: -20px;
	margin-bottom: $baseLine + 10px;
	font-size: 0;

	&__item {
		font-size: 0;
		@extend %#{$block}__item;

		&:before {
			@extend %#{$block}__arrow;
		}
	}

	&__link {
		display: inline-block;
		font-size: $lowSize;
	}

	&__tail {
		font-size: $lowSize;
		color: #babbbf;
		@extend %#{$block}__item;

		&:before {
			@extend %#{$block}__arrow;
		}
	}
}