﻿.navbarToggle {
	width: 69px;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;

	@include bp(portable) {
		width: 150px;
	}

	&:after {
		content: '';
		width: 1px;
		height: 40px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background: $divider;
	}

	&__label {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		user-select: none;
		box-sizing: border-box;
		background-clip: content-box;
		background-color: transparent;
		padding: 6px 0;
		width: 24px;
		height: 4px;
		margin-left: 18px;
		border-top: 4px solid $navbarToggle;
		border-bottom: 4px solid $navbarToggle;

		@include bp(portable) {
			margin-left: 32px;
		}

		transition: .2s;
		transition-delay: .25s;
		&:before,
		&:after {
			position: absolute;
			content: "";
			width: 100%;
			height: inherit;
			background-color: $navbarToggle;
			top: 30%;
			left: 0;
			transition: .2s;
			transition-delay: .25s;
		}
	}

	&__title {
		display: none;
		position: absolute;
		left: 0;
		padding-left: 40px;
		top: -5px;
		font-size: 18px;
		font-weight: 700;
		color: $darkText;

		@include bp(portable) {
			display: block;
		}
	}

	&__input {
		position: absolute;
		top: -1000px;
		left: -1000px;
		&:checked + .navbarToggle__label {
			background-color: transparent;
			border-color: transparent;
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}
