﻿$headerHeight: 50px;
$borderRadius: 8px;
$padding: 22px;

.tmp-modal {
	$block: 'tmp-modal';

	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;

	&_visible {
		display: block;
	}

	&__overlay {
		height: 100%;
		background: rgba(black,.4);
		overflow-y: auto;
		overflow-x: hidden;
		user-select: text;
	}

	&__window {
		width: inherit;
		height: inherit;
		// padding: 21px 25px 17px;
		// lost-center: 300px no-flex;
		padding-bottom: $padding;
		background-color: $modalHeader;
		background-image: linear-gradient(transparent 50%, white 50%);

		@include bp(portable) {
			width: auto;
			height: auto;
			margin-top: 20vh;
			box-shadow: 0 1px 10px rgba(black,.6);
			border-radius: $borderRadius;
			margin-bottom: 100px;
		}

		.#{$block}_type_adaptive & {
			@include bp(portable) {
				transform: translateX(-50%);
				margin-left: 50%;
				display: inline-block;
			}
		}
	}

	&__header {
		// position: relative;
		lost-center: 100% flex;
		line-height: $headerHeight;
		height: $headerHeight;
		padding: 0 $padding 0;
	}

	&__content {
		padding: $padding $padding 0;
		background: white;
		margin-top: 0;
	}

	&__content-inner {
		// fix bottom indent for mobile
		&:last-child {
			> *:last-child {
				margin-bottom: 0;
			}
			border-bottom: 100px solid transparent;
			@include bp(portable) {
				border-bottom-width: 0;
			}
		}
	}

	&__title {
		flex: 1;
		white-space: nowrap;
		max-width: inherit;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 900;
	}

	&__action {
		display: flex;
		align-items: center;
		padding-left: 10px;
	}

	&__loader {
		display: none;
		position: relative;
		height: 200px;

		.#{$block}__content_loading & {
			display: block;
		}
	}

	&__content-inner {
		.#{$block}__content_loading &,
		.#{$block}__content_error & {
			display: none;
		}
	}

	&__error {
		display: none;

		.#{$block}__content_error & {
			display: block;
		}
	}
}