﻿.modalReport {
	width: 100%;

	&--complite {
		transition: width 1s ease-in-out, left 1.5s ease-in-out;
	}

	&__selector {
		width: 100%;
	}

	&__data {
		width: 100%;
	}

	&__loader {
		display: none;
		position: relative;
		height: 100px;
		.modalReport__data--loading & {
			display: block;
		}
	}

	&__error {
		display: none;
		.modalReport__data--error & {
			display: block;
		}
	}

	&__content {
		display: none;
		.modalReport__data--complite & {
			display: block;
		}
	}
}