﻿.tab {
	lost-center: 82%;
	// lost-utility: edit;

	&__set {
		width: 100%;
		position: relative;
		z-index: 10;
		lost-utility: clearfix;
	}

	&__pane {
		width: 100%;
		background: #fff;
		border: 1px solid #e8e8e8;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 1px 0 rgba(0,0,0,.25);
		padding: 32px;
	}
}