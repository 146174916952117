@font-face {
	font-family: 'Lato';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/Lato-Regular.woff2') format('woff2'),
			 url('../fonts/Lato-Regular.woff') format('woff'),
			 url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/Lato-Medium.woff2') format('woff2'),
			 url('../fonts/Lato-Medium.woff') format('woff'),
			 url('../fonts/Lato-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: 600;
	font-style: normal;
	src: url('../fonts/Lato-Semibold.woff2') format('woff2'),
			 url('../fonts/Lato-Semibold.woff') format('woff'),
			 url('../fonts/Lato-Semibold.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/Lato-Bold.woff2') format('woff2'),
			 url('../fonts/Lato-Bold.woff') format('woff'),
			 url('../fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: 800;
	font-style: normal;
	src: url('../fonts/Lato-Heavy.woff2') format('woff2'),
			 url('../fonts/Lato-Heavy.woff') format('woff'),
			 url('../fonts/Lato-Heavy.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: 900;
	font-style: normal;
	src: url('../fonts/Lato-Black.woff2') format('woff2'),
			 url('../fonts/Lato-Black.woff') format('woff'),
			 url('../fonts/Lato-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'icons';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/icons.woff2') format('woff2'),
			 url('../fonts/icons.woff') format('woff'),
			 url('../fonts/icons.ttf') format('truetype');
}

@font-face {
	font-family: 'ALSRubl-Tahoma';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/alsrubl-tahoma-regular.woff') format('woff'),
			 url('../fonts/alsrubl-tahoma-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'ALSRubl-Tahoma';
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/alsrubl-tahoma-bold.woff') format('woff'),
			 url('../fonts/alsrubl-tahoma-bold.ttf') format('truetype');
}
