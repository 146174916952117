﻿.checkbox {
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	padding-left: $baseLine;
	display: block;
	margin: 0 0 .25rem;

	&__input {
		margin: -2px .4rem 0 -1.5rem;
		// display: inline-block;
	}
}