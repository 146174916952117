﻿// .select__btn {
// 		// padding: 5px 3px 4px 6px;
// 		padding-left: 6px;
// 		// margin: 10px 0;
// 		font: inherit;
// 		font-size: 14px;
// 		font-weight: 700;
// 		line-height: 100%;
// }

		// .select {
		// 		position: relative;
		// 		display: inline-block;
		// 		vertical-align: middle;

		// }

			// .select__btn {
			// 		border: 1px solid #c7cacd;
			// 		border-radius: 4px;
			// 		background-color: #fbfbfa;
			// 		color: #555459;		
			// 		height: 30px;

			// &:after {
			// 	content: '123';
			// 	display: inline-block;
			// }

			// }

		// @supports ((-webkit-appearance: none) or
		// 					(-moz-appearance: none) or
		// 					(appearance: none)) {
		// 	.select__btn {
		// 			padding-right: 22px; /* accommodate with the pseudo elements for the dropdown arrow */
		// 			appearance: none;		
		// 	}

		// 	.select:before,
		// 	.select:after {
		// 			content: "";
		// 			position: absolute;
		// 			pointer-events: none;
		// 	}

		// 	.select:after { /*	Custom dropdown arrow */
		// 			content: "\25BC";
		// 			color: #555459 !important;
		// 			// height: 1em;
		// 			font-size: 10px;
		// 			line-height: 1;
		// 			right: 1.2em;
		// 			top: 50%; margin-top: -.5em;
		// 	}
		// }

.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;

	&__select {
		padding: 0 10px;
		font: inherit;
		font-size: 14px;
		font-weight: 600;
		line-height: 28px;

		border: 1px solid #c7cacd;
		border-radius: 4px;
		background-color: #fbfbfa;
		color: #555459;		
		// height: 30px;
	}
}
.jq-selectbox__select:hover {
}
.jq-selectbox__select:active {
}
.jq-selectbox.focused .jq-selectbox__select {
}
.jq-selectbox.disabled .jq-selectbox__select {
}
.jq-selectbox__select-text {
	display: block;
	overflow: hidden;
	width: 100% !important;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jq-selectbox .placeholder {
	color: #888;
}
.jq-selectbox__trigger {
	display: none;
}
.jq-selectbox__trigger-arrow {
	display: none;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow {
	display: none;
}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
	display: none;
}
.jq-selectbox__dropdown {
	box-sizing: border-box;
	// width: 100%;
	margin: 2px 0 0;
	padding: 0;
	border: 1px solid #CCC;
	border-radius: 4px;
	background: #FFF;
	box-shadow: 0 2px 10px rgba(0,0,0,.2);
	font: 14px/18px Arial, sans-serif;
}
.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	border: 1px solid #CCC;
	border-radius: 3px;
	outline: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
	min-height: 18px;
	padding: 5px 10px 6px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	white-space: nowrap;
	color: #231F20;
}
.jq-selectbox li.selected {
	background-color: #A3ABB1;
	color: #FFF;
}
.jq-selectbox li:hover {
	background-color: #08C;
	color: #FFF;
}
.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}

.jq-selectbox li.option {
	padding-left: 25px;
}

.jq-select-multiple {
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/18px Arial, sans-serif;
	color: #333;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
	white-space: nowrap;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}