@charset "UTF-8";
@lost flexbox flex;
@lost gutter 24px;
@lost cycle auto;
.l-hg {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  padding-top: 80px; }
  .page_indent .l-hg {
    transform: translate3d(260px, 0px, 0px); }

.l-grid {
  display: flex;
  flex-wrap: wrap; }

.l-grid--center {
  justify-content: center; }

.l-grid__cell {
  flex: 1; }

.dateSelector > .l-grid__cell, .modal__header > .l-grid__cell {
  flex: none; }

.b404 > .l-grid__cell, .singleCard > .l-grid__cell {
  flex: 0 0 100%;
  max-width: 100%; }

.dateSelector {
  margin-left: -12px; }
  .dateSelector > .l-grid__cell {
    padding-left: 12px; }

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff"), url("../fonts/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato';
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Lato-Medium.woff2") format("woff2"), url("../fonts/Lato-Medium.woff") format("woff"), url("../fonts/Lato-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato';
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Lato-Semibold.woff2") format("woff2"), url("../fonts/Lato-Semibold.woff") format("woff"), url("../fonts/Lato-Semibold.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato';
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Lato-Heavy.woff2") format("woff2"), url("../fonts/Lato-Heavy.woff") format("woff"), url("../fonts/Lato-Heavy.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Lato-Black.woff2") format("woff2"), url("../fonts/Lato-Black.woff") format("woff"), url("../fonts/Lato-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'icons';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"); }

@font-face {
  font-family: 'ALSRubl-Tahoma';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/alsrubl-tahoma-regular.woff") format("woff"), url("../fonts/alsrubl-tahoma-regular.ttf") format("truetype"); }

@font-face {
  font-family: 'ALSRubl-Tahoma';
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/alsrubl-tahoma-bold.woff") format("woff"), url("../fonts/alsrubl-tahoma-bold.ttf") format("truetype"); }

html {
  height: 100%;
  width: 100%; }

body {
  color: #555459;
  background-color: #f9f9f9;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  overflow-x: hidden;
  height: 100%; }

a, .lnk {
  color: #439fe0;
  text-decoration: none;
  cursor: pointer; }
  a:hover, .lnk:hover {
    text-decoration: underline; }

p {
  margin-bottom: 16px; }

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 0; }

audio,
canvas,
progress,
video {
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0); }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

a {
  background: transparent; }

*:focus,
*:active,
*:hover {
  outline: 0; }

hr {
  box-sizing: content-box;
  height: 0; }

ol,
ul {
  list-style: none; }

pre {
  tab-size: 4;
  white-space: pre-wrap; }

q {
  quotes: '\201C' '\201D' '\2018' '\2019'; }

img {
  border: 0;
  max-width: 100%; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button {
  overflow: visible; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  vertical-align: top; }

button,
input,
select[multiple],
textarea {
  background-image: none; }

input,
select,
textarea {
  border-radius: 0;
  box-shadow: none; }

input,
textarea {
  resize: none;
  user-select: text; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h3 {
  font-size: 20px;
  font-weight: 700; }

.accordion {
  lost-center: 100%;
  border-bottom: 1px solid #E8E8E8;
  align-items: baseline;
  padding-top: 16px;
  padding-bottom: 16px; }
  .accordion:last-child {
    border-color: transparent; }
  .accordion__header {
    flex: 1; }
  .accordion__title {
    font-size: 20px;
    margin-bottom: 8px;
    color: #3c4b5b; }
  .accordion__body {
    display: none; }
    .accordion--expanded .accordion__body {
      display: block;
      flex-basis: 100%; }
  .accordion__p--last {
    margin-bottom: 0; }

.alert {
  background: #fff;
  border-radius: 4px;
  position: relative;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #e8e8e8;
  margin-bottom: 12px; }
  .alert__p {
    padding: 12px 16px; }
    .alert__p:last-child {
      margin-bottom: 0; }
  .alert--error {
    border-left: 5px solid #CB5234; }

.article {
  position: relative;
  flex: 1 0 auto;
  padding-top: 48px; }
  .article__header {
    align-items: baseline;
    lost-center: 82% no-flex; }
  .article--card {
    max-width: 634px;
    margin: 0 auto; }
  .article--fill {
    flex: 1 1 100%;
    align-items: stretch; }

.b404 {
  flex: 1;
  text-align: center;
  align-content: center;
  flex-basis: 100%;
  align-self: center;
  margin-top: 100px; }
  .b404__title {
    color: #bbb;
    font-size: 300px;
    line-height: 1;
    font-weight: 700; }
  .b404__message {
    font-size: 22px;
    font-weight: 700;
    color: #999;
    margin-top: 96px; }

.box {
  padding: 32px;
  lost-center: 82%; }

.breadcrumbs__item:before, .breadcrumbs__tail:before {
  font-family: icons;
  content: '\E254';
  color: #babbbf;
  font-weight: 400;
  font-size: 1rem;
  position: absolute;
  top: -1px;
  left: .5rem; }

.breadcrumbs__item, .breadcrumbs__tail {
  display: inline-block;
  padding-left: 2rem;
  position: relative; }

.breadcrumbs {
  lost-center: 95% no-flex;
  white-space: nowrap;
  margin-top: -20px;
  margin-bottom: 34px;
  font-size: 0; }
  @media (min-width: 1280px) {
    .breadcrumbs {
      lost-center: 64pc no-flex; } }
  .breadcrumbs__item {
    font-size: 0; }
  .breadcrumbs__link {
    display: inline-block;
    font-size: 16px; }
  .breadcrumbs__tail {
    font-size: 16px;
    color: #babbbf; }

.btn {
  line-height: 19.2px;
  font-size: 15px;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  padding: 8px 14px 9px;
  margin-bottom: 3px;
  text-align: center;
  font-weight: 900;
  user-select: none;
  display: inline-block;
  position: relative;
  background: #2ab27b;
  color: #fff;
  border: 0;
  font-family: Lato; }
  .btn:hover {
    text-decoration: none; }
  .btn:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px; }
  .btn:hover:after {
    box-shadow: inset 0 -2px rgba(0, 0, 0, 0.25); }
  .btn:active:after {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2); }

.btn--small {
  padding: 6px 10px 5px;
  font-size: 15px; }

.btn--large {
  padding: 14px 32px 16px;
  font-size: 20px; }

.btn--outline {
  background: #fbfbfa;
  color: #555459;
  font-weight: 700;
  text-shadow: none; }
  .btn--outline:after {
    border: 1px solid #C7CACD; }
  .btn--outline:hover:after {
    box-shadow: none; }

.btn--hovered:hover:after {
  border-color: #a0a2a4;
  cursor: pointer; }

.btn--lnk {
  color: #2a80b9;
  cursor: pointer; }
  .btn--lnk:hover {
    background: #fff;
    color: #2a80b9; }

.btn {
  cursor: pointer; }
  .btn--full, .btn_fill {
    width: 100%; }
  .btn_danger {
    background: #cb5234; }
  .btn_warning {
    background: #dfa941; }
  .btn_info {
    background: #3aa3e3; }
  .btn_outline {
    background: #fbfbfa;
    color: #555459;
    font-weight: 700;
    text-shadow: none;
    text-transform: lowercase; }
    .btn_outline:hover {
      box-shadow: none;
      border-color: transparent;
      color: #3aa3e3; }
      .btn_outline:hover:after {
        box-shadow: none; }
    .btn_outline:after {
      border: 1px solid #C7CACD; }
    .btn_outline:active {
      color: #3aa3e3; }
      .btn_outline:active:after {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .btn_transparent {
    background: rgba(255, 255, 255, 0.25);
    color: #555459; }
    .btn_transparent:hover, .btn_transparent:active {
      color: #3aa3e3;
      box-shadow: none;
      border-color: transparent; }
      .btn_transparent:hover:after, .btn_transparent:active:after {
        box-shadow: none; }
  .btn_success {
    background: #56B68B; }
  .btn_small {
    padding: 5px 10px;
    font-size: 14px; }

.box, .card, .dashboard-item, .singleCard {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  border: 1px solid #e8e8e8;
  margin-bottom: 24px; }

.card, .dashboard-item {
  padding: 21px 25px 17px; }

.checkbox {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  padding-left: 24px;
  display: block;
  margin: 0 0 .25rem; }
  .checkbox__input {
    margin: -2px .4rem 0 -1.5rem; }

.close {
  height: 20px;
  width: 20px;
  position: relative;
  box-sizing: border-box;
  line-height: 20px;
  display: inline-block;
  cursor: pointer; }
  .close:before, .close:after {
    width: 20px;
    height: 4px;
    transform: rotate(-45deg);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -10px;
    display: block;
    height: 4px;
    width: 20px;
    background-color: #cbcace;
    transition: all 0.2s ease-out;
    border-radius: 2px; }
  .close:after {
    transform: rotate(45deg); }
  .close:hover:before, .close:hover:after {
    background: #555459; }

.datepicker {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  overflow: auto;
  user-select: none; }
  .datepicker__control {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
    top: 0; }
  .datepicker__item {
    line-height: 28px;
    border: 1px solid #c7cacd;
    padding: 0 10px;
    background: #fbfbfa;
    cursor: pointer;
    flex-shrink: 0; }
    .datepicker__item:not(:first-of-type) {
      margin-left: -1px; }
    .datepicker__item:first-of-type {
      border-radius: 4px 0 0 4px; }
    .datepicker__item:hover {
      border-color: #a0a2a4;
      z-index: 1; }
    .datepicker__item:last-of-type {
      border-radius: 0 4px 4px 0; }

.dateSelector {
  margin-bottom: 12px; }
  .dateSelector__period {
    margin-bottom: 12px;
    max-width: 100%;
    overflow-x: auto; }

.f-icon {
  margin-right: 4px; }
  .f-icon_solo {
    margin-right: 0; }
  .f-icon:before {
    font-family: icons;
    font-size: iherit;
    vertical-align: bottom;
    font-style: normal;
    font-weight: 400; }
  .f-icon_bell-o:before {
    content: '\E015'; }
  .f-icon_cog-o:before {
    content: '\E020'; }
  .f-icon_cogs:before {
    content: '\E021'; }
  .f-icon_credit-card:before {
    content: '\E042'; }
  .f-icon_all-files:before {
    content: '\E059'; }
  .f-icon_smile-o:before {
    content: '\E094'; }
  .f-icon_all-files-alt:before {
    content: '\E107'; }
  .f-icon_create-post:before {
    content: '\E112'; }
  .f-icon_circle-large:before {
    content: '\E225'; }
  .f-icon_cog:before {
    content: '\E517'; }
  .f-icon_bell:before {
    content: '\E502'; }
  .f-icon_rub:before {
    text-transform: uppercase;
    font-family: 'ALSRubl-Tahoma';
    content: 'Р';
    font-size: 18px; }

.flexpane {
  border: 1px solid #e8e8e8;
  border-radius: 6px; }
  .flexpane__header {
    background: #f7f7f7;
    padding: 16px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom: 1px solid #e8e8e8; }
  .flexpane__title {
    font-weight: 700;
    font-size: 18px; }
  .flexpane__content {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit; }

.footer {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px; }
  .footer__brand, .footer__brand-link {
    color: #9e9ea6; }

.form__line {
  margin-bottom: 12px; }
  .form__line--btn {
    margin-top: 24px; }
  .form__line_btn {
    margin-top: 24px; }
  .form__line:last-child {
    margin-bottom: 0; }

.header {
  display: flex;
  background: #fff;
  height: 80px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
  .page_indent .header {
    transform: translate3d(260px, 0, 0); }
    .page_indent .header:after {
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      background: rgba(0, 0, 0, 0.1);
      content: '';
      z-index: 1; }

.hidden {
  display: none; }
  @media (min-width: 800px) {
    .hidden--smartphone {
      display: block; } }

.hs {
  font-size: 50%;
  line-height: 1; }

.input {
  font-size: 20px;
  font-family: Lato; }
  .input__box {
    width: 100%;
    display: flex;
    align-items: baseline; }
  .input__control {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    padding: 12px;
    font-size: inherit;
    font-family: inherit;
    color: #555459;
    line-height: 25px;
    height: 51px;
    flex-grow: 1; }
  .input__label {
    font-weight: 700;
    margin: 4px 0;
    display: block;
    font-size: 16px; }
  .input__cost {
    margin-left: 10px;
    width: 80px;
    font-size: 16px; }

.list {
  margin: 0 0 16px 32px; }
  .list__item {
    position: relative; }
    .list__item:before {
      display: inline-block;
      position: absolute;
      left: -17px;
      content: '•'; }
      .list_order .list__item:before {
        counter-increment: list;
        content: counter(list) "."; }
  .list_order {
    counter-reset: list; }

.loader {
  position: absolute;
  will-change: transform;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 12px; }

.loader {
  border: 1px solid #a4a4a4;
  width: 100%;
  max-width: 240px;
  height: 12px;
  background-image: linear-gradient(135deg, #6187f2 0%, #6187f2 25%, #5679da 25%, #5679da 50%, #6187f2 50%, #6187f2 75%, #5679da 75%, #5679da 100%);
  background-repeat: repeat;
  background-position: 0px 0px;
  background-size: 16px 16px;
  background-clip: content-box;
  padding: 1px;
  animation: loading 1s linear infinite; }
  .loader:before {
    content: attr(data-text);
    display: block;
    position: absolute;
    top: -29px;
    width: 100%;
    text-align: center;
    cursor: default; }

@keyframes loading {
  from {
    background-position: 0px 0px; }
  to {
    background-position: -16px 0px; } }

.logo {
  display: flex;
  align-items: center;
  margin-left: 26px; }
  .logo__img {
    width: 32px;
    height: 32px;
    border-radius: 2px; }
  .logo__brand {
    font-size: 22.4px;
    font-weight: 700;
    color: #404b55;
    margin-left: 15px;
    letter-spacing: -1px; }

.media-list {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px; }
  .media-list_nude {
    border: 0;
    border-radius: inherit; }
  .media-list__item {
    position: relative; }
    .media-list__item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
    .media-list__item:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit; }
    .media-list__item:not(:first-child):before {
      position: absolute;
      border-top: 1px solid #e8e8e8;
      content: '';
      top: 0;
      left: 1rem;
      right: 1rem; }
      .media-list_interactive .media-list__item:hover + .media-list__item:not(:first-child):before {
        border-top: 1px solid transparent; }
  .media-list__item-inner {
    padding: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: inherit;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    color: #555459; }
    .media-list_interactive .media-list__item-inner:before {
      color: #9e9ea6;
      position: absolute;
      top: 50%;
      right: calc(1rem - 6px);
      transform: translateY(-50%);
      content: '\E254';
      display: inline-block;
      font-family: icons;
      font-size: 20px; }
    .media-list_interactive .media-list__item-inner:hover {
      background: #edf7fd;
      text-decoration: none; }
    .media-list_interactive .media-list__item:not(:first-child) .media-list__item-inner:hover {
      border-top-color: #d3ecfa; }
    .media-list_interactive .media-list__item:not(:last-child) .media-list__item-inner:hover {
      border-bottom-color: #d3ecfa; }
  .media-list__media {
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-right: 1rem;
    border-radius: 6px; }
  .media-list__text {
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    margin-right: 1rem;
    flex-grow: 1; }
  .media-list .media-list__title, .media-list .media-list__description {
    padding-right: .5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #555459; }
  .media-list__title {
    font-weight: 700; }

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999; }
  .modal--show {
    display: block; }
  .modal__dialog {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background: transparent;
    width: 100%;
    max-width: 100%;
    overflow-y: auto; }
    @media (min-width: 800px) {
      .modal__dialog {
        top: 20vh; } }
    @media (min-width: 1280px) {
      .modal__dialog {
        max-width: 1100px; } }
  .modal__cover {
    background-color: #f2f2f5;
    background-image: linear-gradient(transparent 50%, white 50%);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    position: relative;
    min-height: 286px; }
    @media (min-width: 800px) {
      .modal__cover {
        margin: 10px;
        margin-bottom: 30px;
        border-radius: 8px; } }
  .modal__header {
    height: 50px;
    padding: 0 22px 0;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 800px) {
      .modal__header {
        position: relative; } }
  .modal__close {
    text-align: right;
    line-height: 0; }
  .modal__section {
    padding: 22px 22px 0;
    background: white;
    margin-bottom: 22px; }
  .modal__title {
    font-size: 20px;
    font-weight: 900; }

.modalReport {
  width: 100%; }
  .modalReport--complite {
    transition: width 1s ease-in-out, left 1.5s ease-in-out; }
  .modalReport__selector {
    width: 100%; }
  .modalReport__data {
    width: 100%; }
  .modalReport__loader {
    display: none;
    position: relative;
    height: 100px; }
    .modalReport__data--loading .modalReport__loader {
      display: block; }
  .modalReport__error {
    display: none; }
    .modalReport__data--error .modalReport__error {
      display: block; }
  .modalReport__content {
    display: none; }
    .modalReport__data--complite .modalReport__content {
      display: block; }

.nav {
  width: 100%;
  padding: 6px 24px 24px 24px; }
  .nav__item {
    font-size: 16px;
    font-weight: 700;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
  .nav__lnk {
    display: block;
    color: #fff;
    padding: 4px 0; }

.navbar {
  width: 260px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: #3c4b5b;
  color: #fff;
  overflow-y: auto;
  transform: translate3d(-260px, 0px, 0px);
  transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
  .page_indent .navbar {
    transform: translate3d(0px, 0px, 0px); }

.navbarToggle {
  width: 69px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative; }
  @media (min-width: 800px) {
    .navbarToggle {
      width: 150px; } }
  .navbarToggle:after {
    content: '';
    width: 1px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #ddd; }
  .navbarToggle__label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    background-clip: content-box;
    background-color: transparent;
    padding: 6px 0;
    width: 24px;
    height: 4px;
    margin-left: 18px;
    border-top: 4px solid #404b55;
    border-bottom: 4px solid #404b55;
    transition: .2s;
    transition-delay: .25s; }
    @media (min-width: 800px) {
      .navbarToggle__label {
        margin-left: 32px; } }
    .navbarToggle__label:before, .navbarToggle__label:after {
      position: absolute;
      content: "";
      width: 100%;
      height: inherit;
      background-color: #404b55;
      top: 30%;
      left: 0;
      transition: .2s;
      transition-delay: .25s; }
  .navbarToggle__title {
    display: none;
    position: absolute;
    left: 0;
    padding-left: 40px;
    top: -5px;
    font-size: 18px;
    font-weight: 700;
    color: #404b55; }
    @media (min-width: 800px) {
      .navbarToggle__title {
        display: block; } }
  .navbarToggle__input {
    position: absolute;
    top: -1000px;
    left: -1000px; }
    .navbarToggle__input:checked + .navbarToggle__label {
      background-color: transparent;
      border-color: transparent; }
      .navbarToggle__input:checked + .navbarToggle__label:before {
        transform: rotate(45deg); }
      .navbarToggle__input:checked + .navbarToggle__label:after {
        transform: rotate(-45deg); }

.page-header {
  width: 100%; }
  .page-header__header {
    lost-center: 100% flex; }
  .page-header__title {
    flex: 1 1 auto; }
  .page-header__description {
    width: 100%; }

.page_scrollOff {
  overflow: hidden;
  user-select: none; }

/**
 * @preserve jQuery PeriodPicker plugin v3.4.1
 * @homepage http://xdsoft.net/jqplugins/periodpicker/
 * @copyright (c) 2016 xdsoft.net Chupurnov Valeriy
 * @license PRO http://xdsoft.net/jqplugins/periodpicker/license/
 */
.xdsoft_noselect,
.xdsoft_noselect * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.period_picker_input {
  border: 0;
  line-height: 28px;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #000;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  outline: 0; }

.period_picker_input:before {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  padding: 0;
  content: '';
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2); }

.period_picker_input:active:before {
  background-color: #f6f5f3;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3); }

.period_picker_input .period_button_text {
  padding: 0 13px;
  position: relative; }

.period_picker_input .icon_clear,
.period_picker_input .icon_calendar {
  width: 16px;
  height: 16px;
  background: 50% no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23010202%22%20d%3D%22M0%206h3v3H0V6zm4%203h3V6H4v3zm4%200h3V6H8v3zm4%200h3V6h-3v3zm0-7v3h3V2h-3zM8%205h3V2H8v3zM4%205h3V2H4v3zm-4%208h3v-3H0v3zm4%200h3v-3H4v3zm4%200h3v-3H8v3z%22%2F%3E%3C%2Fsvg%3E");
  margin-right: 9px;
  margin-bottom: 2px;
  position: relative;
  vertical-align: middle;
  display: inline-block; }

.period_picker_input .icon_clear:after,
.period_picker_input .icon_calendar:after {
  visibility: hidden;
  content: '\00A0'; }

.period_picker_input .icon_clear {
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%3Cpath%20fill%3D%22%23444444%22%20d%3D%22M8%200c-4.418%200-8%203.582-8%208s3.582%208%208%208%208-3.582%208-8-3.582-8-8-8zM8%2014.5c-3.59%200-6.5-2.91-6.5-6.5s2.91-6.5%206.5-6.5%206.5%202.91%206.5%206.5-2.91%206.5-6.5%206.5z%22%3E%3C%2Fpath%3E%0A%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M10.5%204l-2.5%202.5-2.5-2.5-1.5%201.5%202.5%202.5-2.5%202.5%201.5%201.5%202.5-2.5%202.5%202.5%201.5-1.5-2.5-2.5%202.5-2.5z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  margin-right: 0px;
  margin-left: 9px;
  -webkit-transition: opacity 0.2s linear, background 0.2s linear;
  -moz-transition: opacity 0.2s linear, background 0.2s linear;
  -o-transition: opacity 0.2s linear, background 0.2s linear;
  transition: opacity 0.2s linear, background 0.2s linear;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.period_picker_input .icon_clear:hover {
  opacity: 0.8; }

.period_picker_input .icon_clear:active {
  background-color: #ccc; }

.period_picker_input .period_button_dash {
  margin-left: 3px;
  margin-right: 2px; }

.period_picker_box {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 20px 20px;
  background: #fff;
  font-size: 13px;
  cursor: default;
  overflow: hidden;
  z-index: 33001;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
  min-width: 236px;
  min-height: 365px;
  padding-bottom: 80px;
  visibility: hidden; }

.period_picker_box,
.period_picker_box * {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  color: #606060; }

.period_picker_box.with_first_timepicker {
  min-width: -webkit-calc(236px + 87px);
  min-width: -moz-calc(236px + 87px);
  min-width: calc(236px + 87px); }

.period_picker_box.with_first_timepicker.with_second_timepicker {
  min-width: -webkit-calc(236px + 87px + 87px);
  min-width: -moz-calc(236px + 87px + 87px);
  min-width: calc(236px + 87px + 87px); }

.period_picker_box.period_picker_maximize {
  border: 0;
  position: fixed;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  left: 0px;
  top: 0px;
  min-width: 667px;
  min-height: 395px;
  margin-top: 0px;
  width: 100%	!important;
  height: 100%	!important;
  left: 0 !important;
  top: 0	!important; }

.period_picker_box.visible {
  visibility: visible; }

.period_picker_box.animation {
  opacity: 0;
  margin-top: -200px;
  -webkit-transition: .15s linear;
  -moz-transition: .15s linear;
  -o-transition: .15s linear;
  transition: .15s linear;
  -webkit-transition-property: opacity, margin-top;
  -moz-transition-property: opacity, margin-top;
  -o-transition-property: opacity, margin-top;
  transition-property: opacity, margin-top; }

.period_picker_box.active.animation {
  opacity: 1.0;
  margin-top: 0px; }

.period_picker_box.xdsoft_inline,
.period_picker_box.animation.xdsoft_inline {
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  visibility: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  opacity: 1;
  margin: 0;
  z-index: 0; }

.period_picker_box .period_picker_error {
  -webkit-box-shadow: 1px 1px 10px red !important;
  -moz-box-shadow: 1px 1px 10px red !important;
  box-shadow: 1px 1px 10px red !important; }

.period_picker_box .period_picker_head {
  color: #333;
  position: relative;
  height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.period_picker_box .period_picker_head_title {
  display: inline-block;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.5); }

.period_picker_box .period_picker_resizer,
.period_picker_box .period_picker_max_min,
.period_picker_box .period_picker_close {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2256%22%20height%3D%2214%22%20viewBox%3D%220%200%2056%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20fill%3D%22%23000%22%3E%3Cpath%20d%3D%22M16%202h11v10H16V2zm1%201h9v8h-9V3z%22%2F%3E%3Cpath%20d%3D%22M38%209h2V3h-7v2h-2v6h7V9h1V4h-6v1h5v4zm-6-3h5v4h-5V6z%22%2F%3E%3Cpath%20d%3D%22M12%202.714L11.287%202%207%206.286%202.714%202%202%202.714%206.286%207%202%2011.285l.714.715L7%207.714%2011.287%2012l.713-.715L7.714%207%2012%202.714%22%2F%3E%3Cpath%20d%3D%22M44.964%2010.328l.708.708%206.364-6.364-.708-.708-6.364%206.364zm3.415.586l.706.707%203.535-3.534-.706-.707-3.535%203.534z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  opacity: .5;
  right: 0; }

.period_picker_box .period_picker_resizer {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0;
  line-height: 0;
  width: 14px;
  height: 14px;
  cursor: se-resize;
  z-index: 7;
  display: block;
  background-position: -42px 0; }

.period_picker_box .period_picker_close,
.period_picker_box .period_picker_max_min {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 10px;
  cursor: pointer;
  background-position: 0 0; }

.period_picker_box .period_picker_max_min {
  background-position: -14px 0;
  right: 20px; }

.period_picker_box .period_picker_maximize div span.period_picker_max_min {
  background-position: -28px 0; }

.period_picker_box .period_picker_years {
  position: relative;
  left: 0;
  height: 30px;
  margin-bottom: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.period_picker_box .period_picker_years_selector {
  position: absolute;
  width: 100%;
  height: 30px;
  overflow: hidden;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #f4f4f3; }

.period_picker_box .period_picker_years_selector .period_picker_year {
  position: absolute;
  top: 5px;
  width: 3em;
  margin-left: -1.5em;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  z-index: 11;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700; }

.period_picker_box .period_picker_years_selector .period_picker_year:hover {
  color: #000; }

.period_picker_box .period_picker_years_selector .period_picker_year:after {
  content: '';
  display: block;
  width: 1px;
  height: 9px;
  margin: 2px auto;
  background: rgba(0, 0, 0, 0.2); }

.period_picker_box .period_picker_years_selector_container {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px; }

.period_picker_box .period_picker_years_dragger {
  position: absolute;
  top: 0;
  right: 20px;
  width: 70px;
  height: 30px;
  margin-left: -1px;
  font-size: 0;
  line-height: 0;
  z-index: 12;
  cursor: pointer; }

.period_picker_box .period_picker_years_dragger:after {
  content: '';
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.7);
  background: rgba(255, 255, 255, 0.4);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1px;
  right: -1px; }

.period_picker_box .period_picker_years_dragger:hover:after {
  border-color: #000;
  background: rgba(255, 255, 255, 0.6); }

.period_picker_box .period_picker_years_period {
  background: #ffcf00;
  position: absolute;
  right: 100px;
  width: 1px;
  height: 32px;
  font-size: 0;
  line-height: 0;
  z-index: 9;
  display: none; }

.period_picker_box .period_picker_first_letters_td,
.period_picker_box .period_picker_first_letters_tr {
  padding: 0 6px; }

.period_picker_box table {
  border-spacing: 0;
  border-collapse: separate; }

.period_picker_box table th,
.period_picker_box table td {
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  padding: 0;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse; }

.period_picker_box table th.period_picker_first_letters_td,
.period_picker_box table td.period_picker_first_letters_td,
.period_picker_box table th.period_picker_first_letters_tr,
.period_picker_box table td.period_picker_first_letters_tr {
  padding: 0; }

.period_picker_box table td.period_picker_selector_week {
  width: 16px;
  padding: 2px 5px 0;
  text-align: center; }

.period_picker_box .period_picker_days table td {
  vertical-align: top; }

.period_picker_box .period_picker_days table table {
  width: 184px;
  table-layout: fixed;
  border-spacing: 0; }

.period_picker_box .period_picker_days table table.period_picker_first_letters_table th {
  text-align: right;
  font-size: 9px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  color: rgba(0, 0, 0, 0.5); }

.period_picker_box .period_picker_days table table th {
  width: 23px;
  padding: 0 5px;
  height: 25px;
  white-space: nowrap; }

.period_picker_box .period_picker_days table table td {
  width: 23px;
  padding: 0 5px;
  height: 25px;
  cursor: pointer;
  empty-cells: hide;
  vertical-align: middle; }

.period_picker_box .period_picker_days table table td:hover {
  background: #EBEBEB;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.period_picker_box .period_picker_days table table th.period_picker_holiday,
.period_picker_box .period_picker_days table table td.period_picker_holiday {
  color: #C11924; }

.period_picker_box .period_picker_days table table th.period_picker_month {
  border: 0;
  font-weight: 700;
  padding: 14px 0 10px 12px;
  text-transform: uppercase; }

.period_picker_box .period_picker_days table table th.period_picker_month:hover {
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer; }

.period_picker_box .period_picker_days table table td.period_picker_empty,
.period_picker_box .period_picker_days table table td.period_picker_empty:hover {
  cursor: default !important;
  background: none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.period_picker_box .period_picker_days table table td.period_picker_gray_period,
.period_picker_box .period_picker_days table table td.period_picker_gray_period:hover {
  color: #aaa; }

.period_picker_box .period_picker_days table td.period_picker_month12:not(:last-child) {
  position: relative; }

.period_picker_box .period_picker_days table td.period_picker_month12:not(:last-child):after {
  content: '';
  display: block;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -16px;
  bottom: 0;
  right: -5px;
  display: none; }

.period_picker_box .period_picker_days table td.period_picker_selected {
  background: #FFEBA0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.period_picker_box .period_picker_days table td.period_picker_selected :hover {
  background-color: #FFF5D0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.period_picker_box .period_picker_days table td.period_picker_selected_start,
.period_picker_box .period_picker_days table td.period_picker_selected_start:hover {
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
  border-radius: 5px 0 0 0; }

.period_picker_box .period_picker_days table td.period_picker_selected_end,
.period_picker_box .period_picker_days table td.period_picker_selected_end:hover {
  -webkit-border-radius: 0 0 5px 0;
  -moz-border-radius: 0 0 5px 0;
  border-radius: 0 0 5px 0; }

.period_picker_box span.period_picker_selector_week_cap {
  visibility: hidden; }

.period_picker_box span.period_picker_selector_week,
.period_picker_box span.period_picker_selector_week_cap {
  padding: 0;
  background-color: #ccc;
  display: inline-block;
  width: 2px;
  height: 16px; }

.period_picker_box .period_picker_gray_period,
.period_picker_box .period_picker_weekday,
.period_picker_box .period_picker_holiday,
.period_picker_box .period_picker_empty {
  text-align: right; }

.period_picker_box .period_picker_month b {
  display: none; }

.period_picker_box .period_picker_submit_dates {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 13px;
  text-align: center;
  margin: 0 20px 15px;
  background-color: #fff; }

.period_picker_box .period_picker_submit_dates:before {
  content: '';
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px;
  margin-bottom: 20px;
  display: block; }

.period_picker_box .period_picker_submit_dates .input_box {
  padding-right: 7px;
  background: #fff;
  -webkit-box-shadow: inset 0 0 0 1px #cbcbcb;
  -moz-box-shadow: inset 0 0 0 1px #cbcbcb;
  box-shadow: inset 0 0 0 1px #cbcbcb;
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: text; }

.period_picker_box .period_picker_submit_dates .input_focused_yes .input_box {
  -webkit-box-shadow: inset 0 0 0 1px #d0ba65,0 0 10px #fc0;
  -moz-box-shadow: inset 0 0 0 1px #d0ba65,0 0 10px #fc0;
  box-shadow: inset 0 0 0 1px #d0ba65,0 0 10px #fc0; }

.period_picker_box .period_picker_submit_dates input {
  width: 6.1em; }

.period_picker_box .period_picker_submit_dates input.input_control {
  position: relative;
  z-index: 3;
  width: 100%;
  margin: 0;
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: .4em 0;
  border: 0;
  outline: 0;
  background: 0 0;
  color: #000;
  padding-left: 7px; }

.period_picker_box.without_bottom_panel {
  min-height: 311px;
  padding-bottom: 0px; }

.period_picker_box.without_bottom_panel .period_picker_submit_dates {
  display: none; }

.period_picker_box.without_yearsline {
  min-height: 335px; }

.period_picker_box.without_header {
  min-height: 325px; }

.period_picker_box.without_header .period_picker_head {
  height: 10px; }

.period_picker_box.without_header.without_yearsline {
  min-height: 325px; }

.period_picker_box.without_header.without_bottom_panel {
  min-height: 271px; }

.period_picker_box.without_yearsline.without_bottom_panel {
  min-height: 281px; }

.period_picker_box.without_yearsline.without_header.without_bottom_panel {
  min-height: 241px; }

.period_picker_box .period_picker_from_time_block,
.period_picker_box .period_picker_to_time_block,
.period_picker_box .period_picker_from_block,
.period_picker_box .period_picker_to_block {
  margin-right: 5px;
  width: 81px;
  font-size: 13px;
  line-height: 28px;
  color: #000;
  position: relative;
  display: inline-block;
  margin-bottom: 5px; }

.period_picker_box .period_picker_from_time_block,
.period_picker_box .period_picker_to_time_block {
  width: 60px; }

.period_picker_box.xdsoft_norange .period_picker_from_block ~ span {
  display: none; }

.period_picker_box .period_picker_to_time_block,
.period_picker_box .period_picker_to_block {
  margin-right: 0px;
  margin-left: 5px; }

.period_picker_box .period_picker_show {
  display: inline-block;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  outline: 0;
  position: absolute;
  right: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #000;
  line-height: 28px;
  font-size: 13px;
  cursor: pointer;
  border: 0;
  background: 0 0;
  z-index: 3; }

.period_picker_box .period_picker_show + .period_picker_show {
  right: 50px; }

.period_picker_box .period_picker_show:before {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  padding: 0;
  content: '';
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #ffdb4d;
  -webkit-box-shadow: 0 0 0 1px rgba(191, 153, 0, 0.8);
  -moz-box-shadow: 0 0 0 1px rgba(191, 153, 0, 0.8);
  box-shadow: 0 0 0 1px rgba(191, 153, 0, 0.8); }

.period_picker_box .period_picker_show span {
  position: relative;
  padding: 0 13px; }

.period_picker_box .period_picker_show:focus {
  -webkit-box-shadow: 0 0 6px #fc0;
  -moz-box-shadow: 0 0 6px #fc0;
  box-shadow: 0 0 6px #fc0; }

.period_picker_box .period_picker_show:active:before {
  background-color: #fc0;
  -webkit-box-shadow: 0 0 0 1px rgba(159, 127, 0, 0.8);
  -moz-box-shadow: 0 0 0 1px rgba(159, 127, 0, 0.8);
  box-shadow: 0 0 0 1px rgba(159, 127, 0, 0.8); }

.period_picker_box .period_picker_work {
  vertical-align: top;
  height: 100%;
  position: relative; }

.period_picker_box .period_picker_timepicker_box {
  width: 87px;
  display: block;
  top: 0px;
  bottom: 100px;
  left: 0px;
  position: absolute; }

.period_picker_box .period_picker_timepicker_box + .period_picker_days + .period_picker_timepicker_box {
  left: auto;
  right: 0px; }

.period_picker_box.with_first_timepicker .period_picker_days {
  width: -webkit-calc(100% - 87px);
  width: -moz-calc(100% - 87px);
  width: calc(100% - 87px);
  margin-left: 87px; }

.period_picker_box.with_first_timepicker.with_second_timepicker .period_picker_days {
  width: -webkit-calc(100% - 174px);
  width: -moz-calc(100% - 174px);
  width: calc(100% - 174px);
  margin-left: 87px; }

.period_picker_box .xdsoft_navigate {
  background: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20%3F%3E%0D%0A%3Csvg%20height%3D%2212px%22%20version%3D%221.1%22%20viewBox%3D%220%200%209%2012%22%20width%3D%229px%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0D%0A%09%3Ctitle%2F%3E%0D%0A%09%3Cdesc%2F%3E%0D%0A%09%3Cdefs%2F%3E%0D%0A%09%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%3E%0D%0A%09%09%3Cg%20fill%3D%22%23444444%22%20id%3D%22Core%22%20transform%3D%22translate(-218.000000%2C%20-90.000000)%22%3E%0D%0A%09%09%09%3Cg%20id%3D%22chevron-left%22%20transform%3D%22translate(218.500000%2C%2090.000000)%22%3E%0D%0A%09%09%09%09%3Cpath%20d%3D%22M7.4%2C1.4%20L6%2C0%20L-8.8817842e-16%2C6%20L6%2C12%20L7.4%2C10.6%20L2.8%2C6%20L7.4%2C1.4%20Z%22%20id%3D%22Shape%22%2F%3E%0D%0A%09%09%09%3C%2Fg%3E%0D%0A%09%09%3C%2Fg%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E") no-repeat center center;
  position: absolute;
  top: 0px;
  left: -13px;
  bottom: 100px;
  width: 15px;
  opacity: 0.7;
  -webkit-transition: opacity .3s linear;
  -moz-transition: opacity .3s linear;
  -o-transition: opacity .3s linear;
  transition: opacity .3s linear; }

.period_picker_box .xdsoft_navigate:hover {
  opacity: 1.0; }

.period_picker_box .xdsoft_navigate:active {
  margin-top: 2px; }

.period_picker_box .xdsoft_navigate.xdsoft_navigate_next {
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20%3F%3E%0D%0A%3Csvg%20height%3D%2212px%22%20version%3D%221.1%22%20viewBox%3D%220%200%209%2012%22%20width%3D%229px%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0D%0A%09%3Ctitle%2F%3E%0D%0A%09%3Cdesc%2F%3E%0D%0A%09%3Cdefs%2F%3E%0D%0A%09%3Cg%20transform%3D%22rotate(180%204.1999969482421875%2C6)%20%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%3E%0D%0A%09%09%3Cg%20fill%3D%22%23444444%22%20id%3D%22Core%22%20transform%3D%22translate(-218.000000%2C%20-90.000000)%22%3E%0D%0A%09%09%09%3Cg%20id%3D%22chevron-left%22%20transform%3D%22translate(218.500000%2C%2090.000000)%22%3E%0D%0A%09%09%09%09%3Cpath%20d%3D%22M7.4%2C1.4%20L6%2C0%20L-8.8817842e-16%2C6%20L6%2C12%20L7.4%2C10.6%20L2.8%2C6%20L7.4%2C1.4%20Z%22%20id%3D%22Shape%22%2F%3E%0D%0A%09%09%09%3C%2Fg%3E%0D%0A%09%09%3C%2Fg%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E");
  left: auto;
  right: -15px; }

.period_picker_box.without_bottom_panel .xdsoft_navigate {
  bottom: 0px; }

.xdsoft_noselect {
  user-select: none; }

.periodpicker_timepicker {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 1em;
  overflow: hidden;
  font-size: 16px;
  padding: 0; }

.periodpicker_timepicker input.periodpicker_key_hooker {
  border: 0 !important;
  background: transparent !important;
  outline: 0 !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 50%;
  text-align: center;
  cursor: default; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 {
  width: 25%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4 {
  left: 25%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4 + div.periodpicker_col4 {
  left: 50%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4 + div.periodpicker_col4 + div.periodpicker_col4 {
  left: 75%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 {
  width: 33.3333333333%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 + div.periodpicker_col3 {
  left: 33.3333333333%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 + div.periodpicker_col3 + div.periodpicker_col3 {
  left: 66.6666666666%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 {
  width: 50%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 + div.periodpicker_col2 {
  left: 50%; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div > div > div {
  height: 22px;
  line-height: 23px;
  position: relative;
  color: #727272; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div > div > div.active {
  color: #4B4B4B;
  font-size: 1.1em;
  font-weight: 700; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col4 + div.periodpicker_col4:not(.periodpicker_ampmpicker_box):not(.draggable) div.active:before,
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 + div.periodpicker_col2:not(.periodpicker_ampmpicker_box):not(.draggable) div.active:before,
.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col3 + div.periodpicker_col3:not(.periodpicker_ampmpicker_box):not(.draggable) div.active:before {
  content: ":";
  position: absolute;
  font-size: 16px;
  margin-left: -1px;
  left: 0;
  font-weight: 700;
  color: #797575;
  margin-top: -1px;
  z-index: 1; }

.periodpicker_timepicker .periodpicker_timepicker_sliders > div.periodpicker_col2 + div.periodpicker_col2 div.active:before {
  margin-left: -9px; }

.periodpicker_timepicker:before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 20px;
  z-index: 2;
  box-shadow: inset 1px 32px 20px 0 rgba(255, 255, 255, 0.65); }

.periodpicker_timepicker:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0;
  height: 20px;
  box-shadow: inset 0px -32px 20px 0px rgba(255, 255, 255, 0.65);
  z-index: 2; }

.periodpicker_timepicker .periodpicker_timepicker_center {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 0;
  right: 0px; }

.periodpicker_timepicker .periodpicker_timepicker_center:before,
.periodpicker_timepicker .periodpicker_timepicker_center:after {
  content: "";
  position: absolute;
  width: 100%;
  border-top: 1px solid #ccc; }

.periodpicker_timepicker .periodpicker_timepicker_center:before {
  margin-top: -11px; }

.periodpicker_timepicker .periodpicker_timepicker_center:after {
  margin-top: 13px; }

.periodpicker_timepicker_dialog {
  position: absolute;
  height: 300px;
  width: 99px;
  background: #fff;
  z-index: 33001;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
  display: none; }

.periodpicker_timepicker_dialog.visible {
  display: block; }

.period_picker_box {
  display: none; }
  @media (min-width: 960px) {
    .period_picker_box {
      display: block; } }

.period_picker_selector_week {
  visibility: hidden; }

.period_picker_box .period_picker_days table table td {
  width: auto;
  max-height: 26px;
  padding: 0;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  line-height: 26px; }

.period_picker_box .period_picker_days table table.period_picker_first_letters_table th {
  width: auto;
  padding: 0;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase; }

.period_picker_box.with_first_timepicker.with_second_timepicker .period_picker_days {
  margin-left: 79px; }

.period_picker_box .period_picker_days table table th.period_picker_month {
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px; }

.period_picker_input .icon_calendar {
  display: none; }

.period_button_content_body {
  font-weight: 600;
  color: #555459; }

.period_picker_input .period_button_text {
  line-height: 28px;
  display: inline-block; }

.period_picker_input {
  border-radius: 4px;
  border: 1px solid #c7cacd;
  vertical-align: baseline;
  background: #fbfbfa;
  font-family: Lato;
  height: 30px;
  line-height: 30px; }

.period_picker_input .period_button_text {
  padding: 0 10px; }

.period_picker_input:before {
  display: none; }

.period_picker_input:active:before {
  background-color: inherit; }

.period_picker_box .xdsoft_navigate {
  height: 30px;
  bottom: auto;
  top: -45px;
  left: -15px; }

.period_picker_box .xdsoft_navigate.xdsoft_navigate_next {
  right: -15px; }

.periodpicker_item:before {
  content: ':';
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  right: -3px;
  top: -1px;
  color: #fff; }

.periodpicker_item:after {
  content: '00';
  position: absolute;
  font-weight: 700;
  right: -32px;
  color: #fff; }

.periodpicker_item.active:before {
  color: inherit; }

.periodpicker_item.active:after {
  color: inherit; }

.popup {
  position: absolute;
  min-width: 220px;
  max-width: 20pc;
  color: #2c2d30;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 400;
  text-align: left;
  display: none; }
  .popup__items {
    margin: 12px 0; }
  .popup__link {
    margin: 0 12px;
    padding: 0 16px 0 12px;
    display: block;
    color: #2c2d30;
    line-height: 25px;
    white-space: nowrap;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px; }
    .popup__link:hover {
      background: #f2f2f5;
      text-decoration: none; }
  .popup_visible {
    display: block; }

.profile-list {
  lost-center: 100% flex;
  width: 100%; }
  .profile-list:last-child {
    border-color: transparent; }
  .profile-list__item {
    width: 100%;
    lost-flex-container: column;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: baseline; }
    .profile-list__item:last-child {
      margin-bottom: 8px; }
    @media (min-width: 800px) {
      .profile-list__item_accordion {
        padding-right: 114px; } }
    .profile-list__item:not(:last-child) {
      border-bottom: 1px solid #E8E8E8; }
    .profile-list__item_indent:not(:first-child) {
      padding-top: 32px; }
    .profile-list__item_indent:not(:last-child) {
      padding-bottom: 32px; }
  .profile-list__header {
    width: 100%;
    display: flex; }
  .profile-list__title {
    flex-grow: 1; }
  .profile-list__title-inner {
    font-size: 20px;
    margin-bottom: 8px;
    display: inline-block; }
    .profile-list__item_accordion .profile-list__title-inner {
      color: #3c4b5b; }
    .profile-list__item_accordion .profile-list__title-inner:hover {
      color: #3aa3e3;
      cursor: pointer;
      text-decoration: underline; }
  .profile-list__content {
    lost-center: 100% flex;
    width: 100%; }
    @media (min-width: 800px) {
      .profile-list__content {
        flex-wrap: nowrap; }
        .profile-list__item_accordion .profile-list__content {
          flex-wrap: wrap; } }
  .profile-list__annotatio {
    flex-grow: 1; }
  .profile-list__action {
    width: 100%;
    margin-top: 16px; }
    @media (min-width: 800px) {
      .profile-list__action {
        margin-left: 33px;
        margin-top: 0;
        lost-column: 2/6 flex 33px; }
        .profile-list__item_accordion .profile-list__action {
          margin-right: -114px; } }
    .profile-list__item_accordion .profile-list__action {
      width: auto;
      margin-left: 0;
      margin-top: 0;
      flex-shrink: 0; }
  .profile-list__par:last-child {
    margin-bottom: 0; }
  .profile-list__text {
    width: 100%;
    margin-top: 11px;
    max-height: 0;
    transition: max-height .4s ease-out;
    overflow: hidden; }
    .profile-list__item:not(:last-child) .profile-list__text {
      margin-bottom: 6px; }
    .profile-list__content > .profile-list__text:first-child {
      margin-top: 0; }
    .profile-list__item_visible .profile-list__text {
      max-height: 2000px;
      transition: max-height .4s ease-in; }
  @media (min-width: 800px) {
    .profile-list__form_size_small {
      lost-column: 4/6 no-flex; } }
  @media (min-width: 1024px) {
    .profile-list__form_size_small {
      lost-column: 1/2 no-flex; } }

.report {
  width: 100%;
  max-width: 100%;
  text-align: left; }
  .report__line:not(:last-child) {
    margin-bottom: 24px; }
    @media (min-width: 800px) {
      .report__line:not(:last-child) {
        border-bottom: 1px solid #e8e8e8; } }
  .report__cell {
    padding: 0; }
    .report__line--total .report__cell {
      font-weight: 700; }
    .report__line--total .report__cell:before {
      color: #555459; }
  .report__date {
    display: none; }
    @media (min-width: 800px) {
      .report__date {
        display: table-cell;
        font-size: 16px; } }
  .report__total {
    display: block; }
    .report__line--total .report__total {
      font-weight: 700; }
  .report__thead {
    display: none; }
    @media (min-width: 800px) {
      .report__thead {
        display: table-header-group;
        border-bottom: 2px solid #2ab27b; } }
  .report__line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .report__line--total {
      color: #36a64f; }
    @media (min-width: 800px) {
      .report__line {
        display: table-row; } }
  .report__dateTime_fake {
    visibility: visible; }
    @media (min-width: 800px) {
      .report__dateTime_fake {
        visibility: hidden; } }

.report__cell, .report__dateTime {
  flex: 0 0 100%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  display: block; }
  .report__cell:before, .report__dateTime:before {
    content: attr(data-title);
    text-align: left;
    display: inline-block;
    float: left; }

.report__total, .report__dateTime {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px; }

@media (min-width: 800px) {
  .report__thead .report__cell, .report__thead .report__date, .report__thead .report__dateTime {
    font-size: 18px;
    font-weight: 700; } }

@media (min-width: 800px) {
  .report__cell, .report__date, .report__total, .report__dateTime {
    display: table-cell;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
    flex: none;
    padding: 10px 4px 11px; }
    .report__cell:before, .report__date:before, .report__total:before, .report__dateTime:before {
      display: none; } }

@media (min-width: 1280px) {
  .report__cell, .report__date, .report__total, .report__dateTime {
    font-size: 18px; } }

.rub {
  font-family: 'ALSRubl-Tahoma', 'Lato';
  line-height: normal; }
  .rub:before {
    content: '\00a0';
    display: inline-block;
    font-size: 25%;
    line-height: 1; }

.jq-selectbox {
  vertical-align: middle;
  cursor: pointer; }
  .jq-selectbox__select {
    padding: 0 10px;
    font: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    border: 1px solid #c7cacd;
    border-radius: 4px;
    background-color: #fbfbfa;
    color: #555459; }

.jq-selectbox__select-text {
  display: block;
  overflow: hidden;
  width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis; }

.jq-selectbox .placeholder {
  color: #888; }

.jq-selectbox__trigger {
  display: none; }

.jq-selectbox__trigger-arrow {
  display: none; }

.jq-selectbox:hover .jq-selectbox__trigger-arrow {
  display: none; }

.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
  display: none; }

.jq-selectbox__dropdown {
  box-sizing: border-box;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font: 14px/18px Arial, sans-serif; }

.jq-selectbox__search {
  margin: 5px; }

.jq-selectbox__search input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 27px 6px 8px;
  border: 1px solid #CCC;
  border-radius: 3px;
  outline: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
  box-shadow: inset 1px 1px #F1F1F1;
  color: #333;
  -webkit-appearance: textfield; }

.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
  -webkit-appearance: none; }

.jq-selectbox__not-found {
  margin: 5px;
  padding: 5px 8px 6px;
  background: #F0F0F0;
  font-size: 13px; }

.jq-selectbox ul {
  margin: 0;
  padding: 0; }

.jq-selectbox li {
  min-height: 18px;
  padding: 5px 10px 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  color: #231F20; }

.jq-selectbox li.selected {
  background-color: #A3ABB1;
  color: #FFF; }

.jq-selectbox li:hover {
  background-color: #08C;
  color: #FFF; }

.jq-selectbox li.disabled {
  color: #AAA; }

.jq-selectbox li.disabled:hover {
  background: none; }

.jq-selectbox li.optgroup {
  font-weight: bold; }

.jq-selectbox li.optgroup:hover {
  background: none;
  color: #231F20;
  cursor: default; }

.jq-selectbox li.option {
  padding-left: 25px; }

.jq-select-multiple {
  box-sizing: border-box;
  padding: 1px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #FFF;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/18px Arial, sans-serif;
  color: #333;
  cursor: default; }

.jq-select-multiple.focused {
  border: 1px solid #5794BF; }

.jq-select-multiple.disabled {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888; }

.jq-select-multiple ul {
  margin: 0;
  padding: 0; }

.jq-select-multiple li {
  padding: 3px 9px 4px;
  list-style: none;
  white-space: nowrap; }

.jq-select-multiple li:first-child {
  border-radius: 3px 3px 0 0; }

.jq-select-multiple li:last-child {
  border-radius: 0 0 3px 3px; }

.jq-select-multiple li.selected {
  background: #08C;
  color: #FFF; }

.jq-select-multiple li.disabled {
  color: #AAA; }

.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
  background: #CCC;
  color: #FFF; }

.jq-select-multiple li.optgroup {
  font-weight: bold; }

.jq-select-multiple li.option {
  padding-left: 25px; }

.sidebar {
  width: 300px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: red; }

.singleCard {
  padding: 36px 25px;
  width: 100%;
  text-align: center; }
  .singleCard__form {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 800px) {
      .singleCard__form {
        max-width: 375px; } }
  .singleCard__submsg {
    margin-bottom: 16px; }
  .singleCard__formLine {
    margin-bottom: 12px; }
    .singleCard__formLine--btn {
      margin-top: 24px; }
  .singleCard__forgot {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 700; }

@media (min-width: 960px) {
  .summary-report {
    min-width: 800px; } }

.summary-report__header {
  lost-center: 100% flex;
  margin-bottom: -12px; }

.summary-report__header-item {
  lost-column: auto 0 12px flex;
  max-width: 100%;
  margin-bottom: 12px; }

.summary-report__content {
  margin-top: 24px; }

.summary-report__periodpicker {
  display: none; }
  @media (min-width: 960px) {
    .summary-report__periodpicker {
      display: block; } }

.summary-report__datetime {
  position: relative;
  overflow: hidden; }
  @media (min-width: 960px) {
    .summary-report__datetime {
      display: none; } }

.summary-report__loader {
  display: none;
  position: relative;
  height: 200px; }
  .summary-report__content_loading .summary-report__loader {
    display: block; }

.summary-report__content_loading .summary-report__report {
  display: none; }

.summary-report__content_error .summary-report__report {
  display: none; }

.summary-report__error {
  display: none; }
  .summary-report__content_error .summary-report__error {
    display: block; }

.switcher {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  overflow: auto;
  user-select: none; }
  .switcher__control {
    display: none; }
    .switcher__control:checked + .switcher__item {
      background: #ffeab3;
      border-color: #c0b088;
      position: relative; }
  .switcher__item {
    line-height: 28px;
    border: 1px solid #c7cacd;
    padding: 0 10px;
    background: #fbfbfa;
    cursor: pointer;
    flex-shrink: 0; }
    .switcher__item:not(:first-of-type) {
      margin-left: -1px; }
    .switcher__item:first-of-type {
      border-radius: 4px 0 0 4px; }
    .switcher__item:hover {
      border-color: #a0a2a4;
      z-index: 1; }
    .switcher__item:last-of-type {
      border-radius: 0 4px 4px 0; }
    .switcher_solo .switcher__item {
      border-radius: 4px; }

.tab-control {
  width: 100%; }
  .tab-control__tabs {
    width: 100%; }
  .tab-control__item {
    display: inline-block;
    padding: 12px 32px 11px;
    margin-bottom: -1px;
    font-size: 18px;
    font-weight: 700;
    border-width: 1px;
    border-style: solid;
    border-color: transparent; }
    .tab-control__item--selected {
      background: #fff;
      border-color: #e8e8e8 #e8e8e8 white;
      position: relative;
      z-index: 1;
      border-radius: 4px 4px 0 0;
      color: #555459;
      text-decoration: none; }
      .tab-control__item--selected:hover {
        text-decoration: none;
        cursor: default; }
    .tab-control__item--second {
      float: right;
      color: #9e9ea6; }
  .tab-control__pane {
    width: 100%;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    padding: 16px 12.8px; }
    @media (min-width: 800px) {
      .tab-control__pane {
        padding: 32px; } }

.tab {
  lost-center: 82%; }
  .tab__set {
    width: 100%;
    position: relative;
    z-index: 10;
    lost-utility: clearfix; }
  .tab__pane {
    width: 100%;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    padding: 32px; }

.table {
  margin: 0 0 1rem;
  max-width: inherit; }
  .table__row {
    border-bottom: 1px solid #e8e8e8; }
    .table__row:last-child {
      border-bottom: none; }
  .table__header {
    padding: 8px 16px 8px 0; }
    .table__row:first-child .table__header {
      font-weight: 700;
      border-bottom: 2px solid #2ab27b;
      text-align: left; }
      .table_error .table__row:first-child .table__header {
        border-color: #cb5234; }
    .table_press .table__header:last-child {
      width: 100%; }
    .table_press .table__header {
      white-space: nowrap; }
      @media (min-width: 610px) {
        .table_press .table__header {
          padding-right: 48px; } }
  .table__data-inner {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 16px 8px 0; }
  .table_fill {
    width: 100%; }
  .table_fixed {
    table-layout: fixed;
    width: 100%; }

.tableNerta {
  width: 100%;
  margin-bottom: 18px; }
  .tableNerta__caption {
    font-weight: 400;
    text-align: left; }
  .tableNerta__cell {
    text-align: right;
    white-space: nowrap;
    font-size: 16px; }
    .tableNerta__cell--money, .widgetNerta__profit, .tableNerta__cell_money {
      font-weight: 900;
      color: #2ab27b; }

.tabs__lnk {
  display: inline-block;
  padding: 12px 32px 11px;
  margin-bottom: -1px;
  font-size: 18px;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: transparent; }
  .tabs__lnk--selected {
    background: #fff;
    border-color: #e8e8e8 #e8e8e8 white;
    position: relative;
    z-index: 1;
    border-radius: 4px 4px 0 0;
    color: #555459;
    text-decoration: none; }
    .tabs__lnk--selected:hover {
      text-decoration: none;
      cursor: default; }
  .tabs__lnk--second {
    float: right;
    color: #9e9ea6; }

.thing {
  padding: 8px;
  flex-basis: 100%;
  lost-center: 100%;
  border-radius: 4px;
  margin-bottom: 4px;
  border: 1px solid transparent; }
  .thing__expanded, .thing:hover {
    background: #fbfbfa;
    border-color: #e8e8e8; }
  .thing__icon {
    lost-column: 48px none 16px;
    line-height: 0; }
    .thing__icon img {
      width: 48px;
      height: 48px;
      cursor: pointer; }
  .thing__info {
    flex: 1; }
  .thing__action {
    align-self: center;
    margin-right: 6px; }
  .thing__title {
    margin-top: -1px;
    margin-bottom: 1px;
    display: inline-block;
    cursor: pointer; }
    .thing__title:hover {
      text-decoration: underline; }
    .thing:hover .thing__title {
      color: #3aa3e3; }
  .thing__description {
    color: #9e9ea6;
    margin: 0; }
  .thing__options {
    width: 100%;
    margin-top: 15px;
    margin-left: 8px;
    display: none; }
    .thing__options_visible {
      display: block; }
  .thing__form {
    max-width: 480px; }
  .thing__formLine:last-child {
    margin-bottom: 8px; }

.title, .page-header__title {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  margin-bottom: 16px;
  font-weight: 700; }

.tmp-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000; }
  .tmp-modal_visible {
    display: block; }
  .tmp-modal__overlay {
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    overflow-y: auto;
    overflow-x: hidden;
    user-select: text; }
  .tmp-modal__window {
    width: inherit;
    height: inherit;
    padding-bottom: 22px;
    background-color: #f2f2f5;
    background-image: linear-gradient(transparent 50%, white 50%); }
    @media (min-width: 800px) {
      .tmp-modal__window {
        width: auto;
        height: auto;
        margin-top: 20vh;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        margin-bottom: 100px; } }
    @media (min-width: 800px) {
      .tmp-modal_type_adaptive .tmp-modal__window {
        transform: translateX(-50%);
        margin-left: 50%;
        display: inline-block; } }
  .tmp-modal__header {
    lost-center: 100% flex;
    line-height: 50px;
    height: 50px;
    padding: 0 22px 0; }
  .tmp-modal__content {
    padding: 22px 22px 0;
    background: white;
    margin-top: 0; }
  .tmp-modal__content-inner:last-child {
    border-bottom: 100px solid transparent; }
    .tmp-modal__content-inner:last-child > *:last-child {
      margin-bottom: 0; }
    @media (min-width: 800px) {
      .tmp-modal__content-inner:last-child {
        border-bottom-width: 0; } }
  .tmp-modal__title {
    flex: 1;
    white-space: nowrap;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 900; }
  .tmp-modal__action {
    display: flex;
    align-items: center;
    padding-left: 10px; }
  .tmp-modal__loader {
    display: none;
    position: relative;
    height: 200px; }
    .tmp-modal__content_loading .tmp-modal__loader {
      display: block; }
  .tmp-modal__content_loading .tmp-modal__content-inner,
  .tmp-modal__content_error .tmp-modal__content-inner {
    display: none; }
  .tmp-modal__error {
    display: none; }
    .tmp-modal__content_error .tmp-modal__error {
      display: block; }

.unit {
  font-family: 'Lato';
  line-height: normal; }
  .unit:before {
    content: '\00a0';
    display: inline-block;
    font-size: 50%;
    line-height: 1; }

.userbar {
  display: flex;
  align-items: center;
  margin-top: 1px;
  flex: 1;
  justify-content: flex-end;
  padding-right: 32px; }

.userInfo {
  display: flex;
  padding: 16px 24px; }
  .userInfo:hover {
    background: #435466;
    cursor: pointer; }
  .userInfo__name {
    order: 1;
    margin-left: 16px;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    top: 1px;
    text-overflow: ellipsis;
    overflow: hidden; }
    .userInfo__name:before {
      content: attr(data-text);
      display: block;
      color: #6b7682;
      text-transform: uppercase;
      font-size: 12.8px;
      font-weight: 400;
      margin-bottom: -4px; }
  .userInfo__avatar {
    border-radius: 4px;
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    display: block; }

.widget__header {
  flex-wrap: nowrap;
  margin-right: 25px;
  display: flex;
  overflow: hidden;
  margin-bottom: 12px;
  align-items: center; }

.widget__title {
  white-space: nowrap;
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  overflow: hidden; }
  .widget__title:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 15px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 50%);
    z-index: 999; }

.widget__title-inner {
  color: #555459; }
  .widget__title-inner:hover {
    color: #3aa3e3;
    text-decoration: none; }

.widget__controls {
  flex: none;
  display: flex;
  background: #fff; }

.widget__updated {
  margin-right: 2px; }

.widget__attention {
  margin-left: 5px;
  padding-left: 0;
  padding-right: 0;
  color: #cb5234; }

.widget__attention-icon:before {
  font-size: 20px;
  text-align: right; }

.widget__attention-popup {
  top: 46px;
  right: 24px; }

.widgetNerta {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  padding: 21px 25px 17px;
  position: relative;
  border: 1px solid #e8e8e8; }
  .widgetNerta__header {
    flex-wrap: nowrap;
    margin-bottom: 12px;
    align-items: center; }
  .widgetNerta__title {
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    position: relative; }
    .widgetNerta__title:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 15px;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 50%);
      z-index: 999; }
  .widgetNerta__title-inner {
    color: #555459; }
    .widgetNerta__title-inner:hover {
      color: #3aa3e3;
      text-decoration: none; }
  .widgetNerta .l-grid__cell:nth-child(2) {
    flex: none; }
  .widgetNerta__footer {
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: baseline;
    margin-top: -2px; }
    .widgetNerta__footer > .l-grid__cell:nth-child(2) {
      flex: none; }
  .widgetNerta__options-btn {
    margin-left: .25rem;
    margin-right: -5px;
    padding-left: 0;
    padding-right: 0;
    color: #9e9ea6; }
  .widgetNerta__attention-btn {
    padding-left: 0;
    padding-right: 0;
    color: #cb5234; }
  .widgetNerta__options-icon:before, .widgetNerta__attention-icon:before {
    font-size: 20px;
    text-align: right; }
  .widgetNerta__options-popup, .widgetNerta__attention-popup {
    top: 46px;
    right: 24px; }

.widgets {
  lost-center: 92%; }
  @media (min-width: 1280px) {
    .widgets {
      lost-center: 82%; } }
  .widgets section {
    lost-waffle: 1; }
    @media (min-width: 610px) {
      .widgets section {
        lost-waffle: 1/2 2 10px; } }
    @media (min-width: 1024px) {
      .widgets section {
        lost-waffle: 1/3 3 24px; } }
    @media (min-width: 1600px) {
      .widgets section {
        lost-waffle: 1/4; } }
    @media (min-width: 1920px) {
      .widgets section {
        lost-waffle: 1/5; } }

.apps-app {
  lost-center: 95% no-flex; }
  @media (min-width: 1280px) {
    .apps-app {
      lost-center: 64pc no-flex; } }
  .apps-app__aside {
    lost-column: 1/4 no-flex 35px;
    display: none; }
  .apps-app__content {
    lost-column: 4/4 no-flex 35px; }
  .apps-app__icon {
    border-radius: 16px; }

.apps-info__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 2.43rem;
  margin-bottom: 16px; }

.apps-info__things-list-item {
  margin-bottom: 24px; }

.apps-catalog {
  lost-center: 95% no-flex; }
  @media (min-width: 1280px) {
    .apps-catalog {
      lost-center: 64pc no-flex; } }
  .apps-catalog__header {
    width: inherit; }
  .apps-catalog__content {
    width: inherit; }

.widget-preview__footer {
  display: flex; }

.widget-preview__controls {
  display: flex;
  flex: 1; }

.widget-preview__controls-item {
  margin-right: 5px; }

.widget-preview__total {
  flex: none;
  font-weight: 900;
  color: #2ab27b; }

.dashboard-item {
  position: relative;
  width: 100%;
  min-height: 200px;
  margin-bottom: 0; }
  .dashboard-item__edit {
    position: absolute;
    top: 21px;
    right: 20px; }
  .dashboard-item__edit-inner {
    padding-left: 0;
    padding-right: 0;
    color: #9e9ea6; }
  .dashboard-item__edit-icon:before {
    font-size: 20px;
    text-align: right; }
  .dashboard-item__options-popup {
    top: 25px;
    right: 4px;
    z-index: 999; }

.dashboard-page {
  lost-center: 95% no-flex; }
  @media (min-width: 1280px) {
    .dashboard-page {
      lost-center: 82% no-flex; } }
  .dashboard-page__header {
    width: inherit; }
  .dashboard-page__items {
    lost-center: 100%; }
  .dashboard-page__item {
    lost-waffle: 1 1 10px; }
    @media (min-width: 610px) {
      .dashboard-page__item {
        lost-waffle: 1/2 2 10px; } }
    @media (min-width: 960px) {
      .dashboard-page__item {
        lost-waffle: 1/3 3 10px; } }
    @media (min-width: 1024px) {
      .dashboard-page__item {
        lost-waffle: 1/3 3 24px; } }
    @media (min-width: 1600px) {
      .dashboard-page__item {
        lost-waffle: 1/4; } }
    @media (min-width: 1920px) {
      .dashboard-page__item {
        lost-waffle: 1/5; } }

.products-product {
  lost-center: 95% no-flex; }
  @media (min-width: 1280px) {
    .products-product {
      lost-center: 64pc no-flex; } }
  .products-product__header {
    width: inherit; }
  .products-product__content {
    width: inherit; }

.history-modal__pagination {
  text-align: center; }

.history-modal__pagination-item {
  display: inline-block; }
  .history-modal__pagination-item_selected *,
  .history-modal__pagination-item_selected *:hover {
    background: #f2f2f2;
    color: #555459;
    cursor: default; }
  .history-modal__pagination-item:hover * {
    background: #f2f2f2; }

.things-edit-modal__item:not(:last-child) {
  margin-bottom: 12px; }

.things-thing {
  lost-center: 95% no-flex; }
  @media (min-width: 1280px) {
    .things-thing {
      lost-center: 64pc no-flex; } }
  .things-thing__header {
    width: inherit; }
  .things-thing__content {
    width: inherit; }
