﻿.datepicker {
	$block: 'datepicker';

	font-size: 14px;
	font-weight: 600;
	display: flex;
	overflow: auto;
	user-select: none;

	&__control {
		width: 0;
		height: 0;
		position: absolute;
		left: -9999px;
		top: 0;
		// visibility: hidden;
	}

	&__item {
		line-height: 28px;
		border: 1px solid #c7cacd;
		padding: 0 10px;
		background: #fbfbfa;
		cursor: pointer;
		flex-shrink: 0;

		&:not(:first-of-type) {
			margin-left: -1px;
		}

		&:first-of-type {
			// border-left: 1px solid #c7cacd;
			border-radius: 4px 0 0 4px;
		}

		&:hover {
			border-color: #a0a2a4;
			z-index: 1;
		}

		&:last-of-type {
			border-radius: 0 4px 4px 0;
		}
	}
}