﻿.page {
	// &_indent {
	// 	overflow: hidden;
	// }

	&_scrollOff {
		overflow: hidden;
		user-select: none;
	}
}

// .period_picker_box {
// 	display: none !important;
// }