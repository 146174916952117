﻿.b404 {
	@extend %grid--full;
	// max-width: 634px;
	// margin: 0 auto;
	flex: 1;
	text-align: center;
	align-content: center;
	// background: red;
	flex-basis: 100%;
	align-self: center;

	margin-top: 100px;

	&__title {
		color: #bbb;
		font-size: 300px;
		line-height: 1;
		font-weight: 700;
		// margin-top: -170px;
	}

	&__message {
		font-size: 22px;
		font-weight: 700;
		color: #999;
		margin-top: $baseLine * 4;
	}
}