﻿.dashboard-item {
	position: relative;
	width: 100%;
	min-height: 200px; // tmp :D
	@extend .card;
	margin-bottom: 0;

	&__edit {
		position: absolute;
		top: 21px;
		right: 20px;
	}

	&__edit-inner {
		// margin-left: .25rem;
		// margin-right: -5px;
		padding-left: 0;
		padding-right: 0;
		color: $subtext;
	}

	&__edit-icon {
		&:before {
			font-size: 20px;
			text-align: right;
		}
	}

	&__options-popup {
		top: 46px - 21px;
		right: 24px - 20px;
		z-index: 999;
	}
}