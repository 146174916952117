﻿.tableNerta {
	width: 100%;
	margin-bottom: 18px;
	// font-size: 16px;
	// line-height: 20px;

	&__caption {
		font-weight: 400;
		text-align: left;
	}

	&__cell {
		text-align: right;
		white-space: nowrap;
		font-size: 16px;

		&--money,
		&_money {
			// font-size: 16px;
			font-weight: 900;
			color: $cost;
		}
	}
}