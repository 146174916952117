﻿$block: 'switcher';

.#{$block} {
	font-size: 14px;
	font-weight: 600;
	display: flex;
	overflow: auto;
	user-select: none;

	&__control {
		display: none;

		&:checked + .#{$block}__item {
			background: #ffeab3;
			border-color: #c0b088;
			position: relative;
		}
	}

	&__item {
		line-height: 28px;
		border: 1px solid #c7cacd;
		padding: 0 10px;
		background: #fbfbfa;
		cursor: pointer;
		flex-shrink: 0;

		&:not(:first-of-type) {
			margin-left: -1px;
		}

		&:first-of-type {
			// border-left: 1px solid #c7cacd;
			border-radius: 4px 0 0 4px;
		}

		&:hover {
			border-color: #a0a2a4;
			z-index: 1;
		}

		&:last-of-type {
			border-radius: 0 4px 4px 0;
		}

		.#{$block}_solo & {
			border-radius: 4px;
		}
	}
}
