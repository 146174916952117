﻿.unit {
	font-family: 'Lato';
	line-height: normal;

	&:before {
		content: '\00a0';
		display: inline-block;
		font-size: 50%;
		line-height: 1;
	}
}