﻿.alert {
	background: #fff;
	border-radius: 4px;
	position:relative;
	border-width: 1px 1px 1px 5px;
	border-style: solid;
	border-color: #e8e8e8;
	margin-bottom: $baseLine / 2;

	&__p {
		padding: 12px 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&--error {
		border-left: 5px solid #CB5234;
	}
}