﻿.dateSelector {
	@extend %grid--auto;
	@extend %grid--gutters1of2;
	margin-bottom: $baseLine / 2;

	&__period {
		margin-bottom: $baseLine / 2;
		max-width: 100%;
		overflow-x: auto;
	}
}