﻿@lost flexbox flex;
@lost gutter 24px;
@lost cycle auto;

// Holy Grail grid
// ==========================================================================

.l-hg {
	display: flex;
	flex-direction: column;
	// height: 100vh;
	height: 100%;
	transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
	padding-top: $headerHeight;
	// padding-top: $headerHeight + 48px;
	// background: red;

	.page_indent & {
		transform: translate3d($navbarWidth, 0px, 0px);
	}
}

// .l-body {
// 	display: flex;
// 	flex: 1 0 auto;
// 	flex-direction: column;
// }

// .l-content {
// }

// Grid templates
// ==========================================================================

%grid {
	display: flex;
	flex-wrap: wrap;
}

%l-left {
	padding-left: $l-left;
	@include bp(lg) {
		padding-left: $l-left-xl;
	}

	@include bp(xxl) {
		padding-left: $l-left-xxl;
	}
}

%l-right {
	padding-right: $l-right;
	@include bp(lg) {
		padding-right: $l-right-xl;
	}

	@include bp(xxl) {
		padding-right: $l-right-xxl;
	}
}

@mixin grid-sizing($name: "") {
	$grids: "auto" "none" "", "fit" "1" "",
	"full" "0 0 100%" "100%",
	"1of2" "0 0 50%" "50%",
	"1of3" "0 0 33.333%" "33.333%",
	"1of4" "0 0 25%" "25%",
	"1of5" "0 0 20%" "20%",
	"1of6" "0 0 16.666%" "16.666%",
	"1of7" "0 0 14.285%" "14.285%",
	"1of8" "0 0 12.5%" "12.5%",
	"1of9" "0 0 11.11%" "11.11%";
	$sep: "-";
	@if $name == "" {
		$sep: "";
	}
	@each $size, $flex, $maxWidth in $grids {
		%#{$name}#{$sep}grid--#{$size} > .l-grid__cell {
			flex: #{$flex};
			max-width: #{$maxWidth};
			// @if $ie {
			// 	max-width: #{$maxWidth};
			// }
		}
	}
}

// Grid system
// ==========================================================================

.l-grid {
	@extend %grid;
	&--center {
		justify-content: center;
	}
}

.l-grid__cell {
	flex: 1;
}

// Create the default grid-sizing modifiers
@include grid-sizing;

// Create grid-sizing modifiers for each breakpoint
@each $bp in $bps {
	$name: nth($bp, 2);
	@include bp($name) {
		@include grid-sizing($name);
	}
}

@mixin grid-gutters($name: "") {
	$sep: "-";
	@if $name == "" {
		$sep: "";
	}
	%#{$name}#{$sep}grid--gutters {
		margin-left: -$baseLine;
		& > .l-grid__cell {
			padding-left: $baseLine;
		}
	}
	%#{$name}#{$sep}grid--gutters1of2 {
		margin-left: -$baseLine / 2;
		& > .l-grid__cell {
			padding-left: $baseLine / 2;
		}
	}
}

// Create the default grid-gutters modifiers
@include grid-gutters;

// Create grid-gutters modifiers for each breakpoint
@each $bp in $bps {
	$name: nth($bp, 2);
	@include bp($name) {
		@include grid-gutters($name);
	}
}
