﻿.rub {
	font-family: 'ALSRubl-Tahoma', 'Lato';
	line-height: normal;

	// tmp
	&:before {
		content: '\00a0';
		display: inline-block;
		font-size: 25%;
		line-height: 1;
	}
}