﻿.logo {
	display: flex;
	align-items: center;
	margin-left: 26px;

	&__img {
		width: 32px;
		height: 32px;
		border-radius: 2px;
	}

	&__brand {
		font-size: 22.4px;
		font-weight: 700;
		color: $brand;
		margin-left: 15px;
		letter-spacing: -1px;
	}
}