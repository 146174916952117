﻿// Описание таблицы .report
// ==========================================================================

.report {
	width: 100%;
	max-width: 100%;
	text-align: left;
	// transition: opacity 3s ease 0s;

	&__line {
		&:not(:last-child) {
			margin-bottom: $baseLine;

			@include bp(portable) {
				border-bottom: 1px solid #e8e8e8;
			}
		}
	}


	&__cell {
		@extend %reportMorphCell;
		@extend %reportCapThead;
		@extend %reportCellRestore;
		padding: 0;

		.report__line--total & {
			font-weight: 700;
		}


		&:before {
			.report__line--total & {
				color: $basecolor;
				// font-weight: 400;
			}
		}
	}

	&__date {
		display: none;
		@extend %reportCapThead;
		@extend %reportCellRestore;

		@include bp(portable) {
			display: table-cell;
			font-size: 16px;
		}
	}

	&__total {
		display: block;
		@extend %reportCap;
		@extend %reportCellRestore;

		.report__line--total & {
			font-weight: 700;
		}
	}

	&__thead {
		display: none;
		@include bp(portable) {
			display: table-header-group;
			border-bottom: 2px solid #2ab27b;
		}
	}

	&__line {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&--total {
			color: #36a64f;
		}

		// Здесь по-идее нужно дооформить под несколько колонок таблицу
		// @include bp(lap) {
		// 	display: inline-flex;
		// 	max-width: 49%;

		// 	&:nth-child(even) {
		// 		padding-left: 30px;
		// 	}
		// }

		@include bp(portable) {
			display: table-row;
		}
	}

	&__dateTime {
		@extend %reportMorphCell;
		@extend %reportCap;
		@extend %reportCapThead;
		@extend %reportCellRestore;

		&_fake {
			visibility: visible;
			@include bp(portable) {
			visibility: hidden;
			}
		}
	}
}

// Описываем тихие классы для таблицы .report
// ==========================================================================

// Пилим таблицу на блоки
%reportMorphCell {
	flex: 0 0 100%;
	text-align: right;
	white-space: nowrap;
	overflow: hidden;
	display: block;

	&:before {
		content: attr(data-title);
		text-align: left;
		display: inline-block;
		float: left;
	}
}

// Заголовки в thead для мобилок
%reportCap {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 8px;
}

// Заголовки в thead для таблицы
%reportCapThead {
	@include bp(portable) {
		.report__thead & {
			font-size: 18px;
			font-weight: 700;
			// text-align: left;
			// padding-left: 10px;
		}
	}
}

// Восстанавливаем ячейки таблицы
%reportCellRestore {
	@include bp(portable) {
		display: table-cell;
		font-size: 16px;
		margin-bottom: 0;
		font-weight: 400;
		flex: none;
		padding: 10px 4px 11px;

		&:before {
			display: none;
		}
	}

	@include bp(lg) {
		font-size: 18px;
	}

}