﻿.page-header {
	width: 100%;

	&__header {
		lost-center: 100% flex;
	}

	&__title {
		@extend .title;
		flex: 1 1 auto;
	}

	&__description {
		width: 100%;
	}
}