﻿.close {
	$size: 20px;
	$width: $size;
	$depth: 4px;
	$radius: 2px;

	height: $size;
	width: $width;
	position: relative;
	box-sizing: border-box;
	line-height: $size;
	display: inline-block;
	cursor: pointer;

	&:before, &:after {
		width: $width;
		height: $depth;
		transform: rotate(-45deg);
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -$depth/2;
		margin-left: -$width/2;
		display: block;
		height: $depth;
		width: $width;
		background-color: #cbcace;
		transition: all 0.2s ease-out;
		border-radius: $radius;
	}

	&:after {
		transform: rotate(45deg);
	}

	&:hover {
		&:before, &:after {
			background: $basecolor;
		}
	}
}