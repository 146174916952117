﻿.widgets {
	// @extend %l-left;
	// @extend %l-right;
	// @extend %grid--gutters;
	// @extend %grid--full;
	// @extend %lap-grid--1of2;
	// @extend %desk-grid--1of3;
	// @extend %xl-grid--1of4;

	lost-center: 92%;

	@include bp(lg) {
		lost-center: 82%;
	}

	section {
		lost-waffle: 1;

		@include bp(lap) {
			lost-waffle: 1/2 2 10px;
		}
		@include bp(desk) {
			lost-waffle: 1/3 3 $baseLine;
			// lost-row: 1/3;
			// lost-column: 1/3 3;
		}
		@include bp(xl) {
			lost-waffle: 1/4;
		}
		@include bp(fh) {
			lost-waffle: 1/5;
		}
	}
}
