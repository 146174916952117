﻿.thing {
	padding: 8px;
	flex-basis: 100%;
	lost-center: 100%;
	border-radius: 4px;
	margin-bottom: 4px;
	border: 1px solid transparent;

	&__expanded {
		background: #fbfbfa;
		border-color: #e8e8e8;
	}

	&:hover {
		@extend .thing__expanded;
	}

	&__icon {
		lost-column: 48px none 16px;
		line-height: 0;

		img {
			width: 48px;
			height: 48px;
			cursor: pointer;
		}
	}

	&__info {
		flex: 1;
	}

	&__action {
		align-self: center;
		margin-right: 6px;
	}

	&__title {
		margin-top: -1px;
		margin-bottom: 1px;
		display: inline-block;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}

		.thing:hover & {
			color: $lnk2;
		}
	}

	&__description {
		color: $subtext;
		margin: 0;
	}

	&__options {
		width: 100%;
		margin-top: 15px;
		margin-left: 8px;
		display: none;
		&_visible {
			display: block;
	}
	}

	&__form {
		max-width: 480px;
	}

	&__formLine {
		&:last-child {
			margin-bottom: 8px;
		}
	}
}