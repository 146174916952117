﻿$width: 270px;
$gap: 24px;
$gap-mobile: 10px;

.dashboard-page {
	@include width(widgets);

	&__header {
		width: inherit;
	}

	&__items {
		// width: inherit;
		lost-center: 100%;

	// columns: 7 $width;
	// column-gap: $gap-mobile;
	// @include bp(p960) {
	// 	column-gap: $gap;
	// }
	// line-height: 0;
	// vertical-align: bottom;
	}

	&__item {
	// 	display: inline-block;
	// 	// overflow: hidden;
	// 	break-before: region;
	// 	line-height: $baseLine;
	// 	width: 100%;
	// 	margin-bottom: $gap-mobile;
	// 	@include bp(p960) {
	// 		margin-bottom: $gap;
	// 	}

		lost-waffle: 1 1 $gap-mobile;
		// @extend .card;

		@include bp(lap) {
			lost-waffle: 1/2 2 $gap-mobile;
		}

		@include bp(p960) {
			lost-waffle: 1/3 3 $gap-mobile;
		}

		@include bp(desk) {
			lost-waffle: 1/3 3 $gap;
			// lost-row: 1/3;
			// lost-column: 1/3 3;
		}
		@include bp(xl) {
			lost-waffle: 1/4;
		}
		@include bp(fh) {
			lost-waffle: 1/5;
		}
	}
}