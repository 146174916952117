﻿.widget {
	&_theme_mr-white {
		
	}

	&__header {
		flex-wrap: nowrap;
		margin-right: 25px;
		display: flex;
		overflow: hidden;
		// align-items: baseline;
		margin-bottom: 12px;
		align-items: center;
	}

	&__title {
		// flex-grow: 1;
		white-space: nowrap;
		flex: 1;
		font-size: 20px;
		font-weight: 700;
		position: relative;
		overflow: hidden;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 15px;
			background-image: linear-gradient(to right, rgba(#fff, 0) 0, #fff 50%);
			// background: red;
			z-index: 999;
		}
	}

	&__title-inner {
		color: $basecolor;

		&:hover {
			color: $lnk2;
			text-decoration: none;
		}
	}

	&__controls {
		flex: none;
		display: flex;
		background: #fff; // need mod _theme?
	}

	&__updated {
		margin-right: 2px;
	}

	&__attention {
		margin-left: 5px;
		padding-left: 0;
		padding-right: 0;
		color: #cb5234;
	}

	&__attention-icon {
		&:before {
			font-size: 20px;
			text-align: right;
		}
	}

	&__attention-popup {
		top: 46px;
		right: 24px;
	}
}