﻿.popup {
	position: absolute;
	min-width: 220px;
	max-width: 20pc;
	color: #2c2d30;
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,.12);
	border-radius: 6px;
	border: 1px solid rgba(0,0,0,.15);
	font-weight: 400;
	text-align: left;
	display: none;
	// top: 153px;
	// left: 853px;

	&__items {
		margin: 12px 0;
	}

	&__item {
	}

	&__link {
		margin: 0 12px;
		padding: 0 16px 0 12px;
		display: block;
		color: #2c2d30;
		line-height: 25px;
		white-space: nowrap;
		border-radius: 4px;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 15px;

		&:hover {
			background: #f2f2f5;
			text-decoration: none;
		}
	}

	&_visible {
		display: block;
	}
}