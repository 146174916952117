﻿.accordion {
	// flex-basis: 100%;
	lost-center: 100%;
	border-bottom: 1px solid #E8E8E8;
	align-items: baseline;
	padding-top: 16px;
	padding-bottom: 16px;

	&:last-child {
		border-color: transparent;
	}

	&__header {
		flex: 1;
	}

	&__title {
		font-size: 20px;
		margin-bottom: 8px;
		color: $navbarBg;
	}

	&__body {
		display: none;

		.accordion--expanded & {
			display: block;
			flex-basis: 100%;
		}
	}

	&__action {
		// align-self: center;
	}

	&__p {
		&--last {
			margin-bottom: 0;
		}
	}
}