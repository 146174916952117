﻿.form {
	&__line {
		margin-bottom: $baseLine / 2;

		&--btn {
			margin-top: $baseLine;
		}

		&_btn {
			margin-top: $baseLine;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}