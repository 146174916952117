﻿.sidebar {
	width: 300px;
	display: none;

	position: absolute;
	top: 0;
	right: 0;

	height: 100%;

	background: red;
}