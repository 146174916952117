$lowSize: 16px !global;
$baseSize: 18px !global;
$baseLine: 24px !global;

$navbarWidth: 260px !global;
$headerHeight: 80px !global;

$baseFont: Lato !global;

// Colors

$basecolor: #555459 !global;
$darkText: #404b55 !global;
$navbarToggle: $darkText !global;
$brand: $darkText !global;
$divider: #ddd !global;
$cost: #2ab27b !global; // #36a64f
$modalHeader: #f2f2f5 !global;

$navbarBg: #3c4b5b !global;
$lnk: #439fe0 !global;
$lnk2: #3aa3e3 !global;
$btn-lnk: #2a80b9 !global;
$navDark: #6b7682 !global;
$navHover: #435466 !global;

$line: #e8e8e8 !global;

$subtext: #9e9ea6 !global;

// Indents
$l-left: 4% !global;
$l-right: 4% !global;
$l-left-xl: 9% !global;
$l-right-xl: 9% !global;
$l-left-xxl: 9% !global;
$l-right-xxl: 9% !global;

// Layout width
$width-content: 60% !global;
$width-widgets: 82% !global;