﻿.nav {
	width: 100%;
	padding: 6px 24px 24px 24px;

	&__group {
	}

	&__item {
		font-size: 16px;
		font-weight: 700;
		text-shadow: 0 1px 1px rgba(0,0,0,.1);
	}

	&__lnk {
		display: block;
		color: #fff;
		padding: 4px 0;
	}
}