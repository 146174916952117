.input {
	font-size: 20px;
	font-family: $baseFont;

	&__box {
		width: 100%;
		// display: inline-block;
		display: flex;
		align-items: baseline;
	}

	&__control {
		border: 1px solid #c5c5c5;
		border-radius: 4px;
		padding: 12px;
		font-size: inherit;
		font-family: inherit;
		color: $basecolor;
		line-height: 25px;
		height: 51px;
		// width: 100%;
		flex-grow: 1;
	}

	&__label {
		font-weight: 700;
		margin: 4px 0;
		display: block;
		font-size: $lowSize;
	}

	&__cost {
		margin-left: 10px;
		width: 80px;
		// background: red;
		font-size: $lowSize;
		// color: $subtext;
	}
}