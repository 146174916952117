﻿$block: 'profile-list';
$accordion-indent: 114px;

.#{$block} {
	lost-center: 100% flex;
	width: 100%;
	// lost-utility: edit;

	&:last-child {
		border-color: transparent;
	}

	&__item {
		width: 100%;
		lost-flex-container: column;
		padding-top: 16px;
		padding-bottom: 16px;
		align-items: baseline;

		&:last-child {
			margin-bottom: 8px;
		}

		&_accordion {
			@include bp(portable) {
				padding-right: $accordion-indent;
			}
		}

		&:not(:last-child) {
			border-bottom: 1px solid #E8E8E8;
		}

		&_indent {
			&:not(:first-child) {
				padding-top: 32px;
			}
			&:not(:last-child) {
				padding-bottom: 32px;
			}
		}
	}

	&__header {
		width: 100%;
		display: flex;
	}

	&__title {
		flex-grow: 1;
	}

	&__title-inner {
		font-size: 20px;
		margin-bottom: 8px;
		display: inline-block;

		.#{$block}__item_accordion & {
			color: $navbarBg;
		}

		&:hover {
			.#{$block}__item_accordion & {
				color: $lnk2;
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	&__content {
		lost-center: 100% flex;
		width: 100%;
		@include bp(portable) {
			flex-wrap: nowrap;
			.#{$block}__item_accordion & {
				flex-wrap: wrap;
			}
		}
	}

	&__annotatio {
		flex-grow: 1;
	}

	&__action {
		width: 100%;
		margin-top: 16px;
		@include bp(portable) {
			margin-left: 33px;
			margin-top: 0;
			lost-column: 2/6 flex 33px;

			.#{$block}__item_accordion & {
				margin-right: -$accordion-indent;
			}
		}
		.#{$block}__item_accordion & {
			width: auto;
			margin-left: 0;
			margin-top: 0;
			flex-shrink: 0;
		}
	}

	&__par {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__text {
		width: 100%;
		margin-top: 11px;
		.#{$block}__item:not(:last-child) &{
			margin-bottom: 6px;
		}

		.#{$block}__content > & {
			&:first-child {
				margin-top: 0;
			}
		}

		// Accourdion annimation
		max-height: 0;
		transition: max-height .4s ease-out;
		overflow: hidden;

		.#{$block}__item_visible & {
			max-height: 2000px;
			transition: max-height .4s ease-in;
		}
	}

	&__form {
		&_size_small {
			@include bp(portable) {
				lost-column: 4/6 no-flex;
			}

			@include bp(desk) {
				lost-column: 1/2 no-flex;
			}
		}
	}
}