﻿$block: 'summary-report';

.#{$block} {
	@include bp(p960) {
		min-width: 800px;
	}

	&__header {
		lost-center: 100% flex;
		margin-bottom: -12px;
	}

	&__header-item {
		lost-column: auto 0 12px flex;
		max-width: 100%;
		margin-bottom: 12px;
	}

	&__content {
		margin-top: $baseLine;
	}

	&__periodpicker {
		display: none;
		@include bp(p960) {
			display: block;
		}
	}

	&__datetime {
		position: relative;
		overflow: hidden;
		@include bp(p960) {
			display: none;
		}
	}

	&__loader {
		display: none;
		position: relative;
		height: 200px;

		.#{$block}__content_loading & {
			display: block;
		}
	}

	&__report {
		.#{$block}__content_loading & {
			display: none;
		}
		.#{$block}__content_error & {
			display: none;
		}
	}

	&__error {
		display: none;

		.#{$block}__content_error & {
			display: block;
		}
	}
}