﻿.singleCard {
	@extend %card;
	@extend %grid--full;
	padding: ($baseLine * 1.5) 25px;
	width: 100%;
	text-align: center;

	&__form {
		width: 100%;
		margin: 0 auto;

		@include bp(portable) {
			max-width: 375px;
		}
	}

	&__submsg {
		margin-bottom: $baseLine / 1.5;

		// strong {
		// 	font-size: 16px;
		// 	font-weight: 800;
		// }
	}

	&__formLine {
		margin-bottom: $baseLine / 2;

		&--btn {
			margin-top: $baseLine;
		}
	}

	&__forgot {
		margin-top: $baseLine;
		font-size: 16px;
		font-weight: 700;
	}
}