﻿.widget-preview {
	&__table {

	}

	&__footer {
		display: flex;
	}

	&__controls {
		display: flex;
		flex: 1;
	}

	&__controls-item {
		margin-right: 5px;
	}

	&__total {
		flex: none;
		font-weight: 900;
		color: $cost;
	}
}