﻿// Buttons shadow class
// ==========================================================================

%btn {
	line-height: 19.2px;
	font-size: 15px;
	border-radius: 4px;
	text-shadow: 0 1px 1px rgba(0,0,0,.1);
	white-space: nowrap;
	padding: 8px 14px 9px;
    margin-bottom: 3px;
	text-align: center;
	font-weight: 900;
	user-select: none;
	display: inline-block;
	position: relative;
	background: #2ab27b;
	color: #fff;
	border: 0;
	font-family: $baseFont;

	&:hover {
		text-decoration: none;
	}

	&:after {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 4px;
	}

	&:hover:after {
		box-shadow: inset 0 -2px rgba(0,0,0,.25);
	}

	&:active:after {
		box-shadow: inset 0 2px 1px rgba(0,0,0,.2);
	}
}

%btn--small {
	padding: 6px 10px 5px;
	font-size: 15px;
}

%btn--large {
	padding: 14px 32px 16px;
	font-size: 20px;
}

%btn--full {
	width: 100%;
}

%btn--outline {
	background: #fbfbfa;
	color: #555459;
	font-weight: 700;
	text-shadow: none;

	&:after {
		border: 1px solid #C7CACD;
	}

	&:hover {
		&:after {
			box-shadow: none;
		}
	}
}

%btn--hovered {
	&:hover {
		&:after {
			border-color: #a0a2a4;
			cursor: pointer;
		}
	}
}

%btn--lnk {
	color: $btn-lnk;
	cursor: pointer;

	&:hover {
		background: #fff;
		color: $btn-lnk;
	}
}

// Buttons class
// ==========================================================================

.btn {
	@extend %btn;
	cursor: pointer;

	&--small {
		@extend %btn--small;
	}

	&--outline {
		@extend %btn--outline;
	}

	&--large {
		@extend %btn--large;
	}

	&--lnk {
		@extend %btn--lnk;
	}

	&--hovered {
		@extend %btn--hovered;
	}

	&--full,
	&_fill {
		width: 100%;
	}

	&_danger {
		background: #cb5234;
	}

	&_warning {
		background: #dfa941;
	}

	&_info {
		background: #3aa3e3;
	}

	&_outline {
		background: #fbfbfa;
		color: #555459;
		font-weight: 700;
		text-shadow: none;
		text-transform: lowercase;

		&:hover {
			box-shadow: none;
			border-color: transparent;
			color: #3aa3e3;

			&:after {
				box-shadow: none;
			}
		}

		&:after {
			border: 1px solid #C7CACD;
		}

		&:active{
			color: #3aa3e3;

			&:after {
				box-shadow: inset 0 1px 1px rgba(0,0,0,.2);
			}
		}
	}

	&_transparent {
		background: rgba(255,255,255,.25);
		color: $basecolor;

		&:hover,
		&:active {
			color: #3aa3e3;
			box-shadow: none;
			border-color: transparent;

			&:after {
				box-shadow: none;
			}
		}
	}

	&_success {
		background: #56B68B;
	}

	&_small {
		padding: 5px 10px;
		font-size: 14px;
	}
}

// .btn + .btn {
// 	margin-left: 6px;
// }