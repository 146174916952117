﻿@mixin width($type) {
	@if $type == "content" {
		lost-center: 95% no-flex;

		@include bp(lg) {
			lost-center: 64pc no-flex;
			// lost-center: 90% no-flex;
		}

		@include bp(fh) {
			// lost-center: 60% no-flex;
		}
	}

	@if $type == "widgets" {
		lost-center: 95% no-flex;

		@include bp(lg) {
			lost-center: 82% no-flex;
		}

		@include bp(fh) {
		}
	}
}