﻿.history-modal {

	&__pagination {
		text-align: center;
	}

	&__pagination-item {
		display: inline-block;

		&_selected {
			*,
			*:hover {
				background: #f2f2f2;
				color: $basecolor;
				cursor: default;
			}
		}

		&:hover {
			* {
				background: #f2f2f2;
			}
		}
	}
}