%card {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 0 rgba(0,0,0,.25);
	position:relative;
	border: 1px solid #e8e8e8;
	margin-bottom: $baseLine;
}

.card {
	@extend %card;
	padding: 21px 25px 17px;
}
