﻿.flexpane {
	border: 1px solid #e8e8e8;
	border-radius: 6px;

	&__header {
		background: #f7f7f7;
		padding: 16px;
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
		border-bottom: 1px solid #e8e8e8;
	}

	&__title {
		font-weight: 700;
		font-size: $baseSize;
	}

	&__content {
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
	}
}